import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();

logger.push({
    'logglyKey': window._env_.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
    'sendConsoleErrors' : true,
    'tag' : window._env_.REACT_APP_LOGGLY_TAG,
});

export default logger;