import React from 'react';

import './styles.scss';

import CircleLoader from '../index';

const GlobalCircleLoader = () => {
    return(
        <div className="default-loader-container">
            <CircleLoader />
        </div>
    );
};

export default GlobalCircleLoader;