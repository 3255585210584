import { APP_LOCALE } from "../contstants";
import { BRITISH, ENGLISH, SWEDEN } from "../../../config/languages";

export const BASE_ROUTE_LOCALE = {
    [APP_LOCALE.swedish]: '/',
    [APP_LOCALE["english-british"]]: '/uk',
    [APP_LOCALE.US]: '/',
}

export const TRANSLATIONS_LOCALE = {
    [APP_LOCALE.swedish]: SWEDEN,
    [APP_LOCALE["english-british"]]: BRITISH,
    [APP_LOCALE.US]: ENGLISH,
};

export const LOCALE_AUTH_ENV_CONFIG = {
    [APP_LOCALE.swedish]: window._env_.AUTH_WEB_URL,
    [APP_LOCALE.US]: window._env_.AUTH_WEB_INTERNATIONAL_URL,
    [APP_LOCALE["english-british"]]: window._env_.AUTH_WEB_UK_URL,
};

export const LOCALE_TEACHERS_ENV_CONFIG = {
    [APP_LOCALE.swedish]: window._env_.TEACHERS_WEB_URL,
    [APP_LOCALE["english-british"]]: window._env_.TEACHERS_INTERNATIONAL_URL,
    [APP_LOCALE.US]: window._env_.TEACHERS_INTERNATIONAL_URL,
};

export const LOCALE_DISTRICTDASH_ENV_CONFIG = {
    [APP_LOCALE.swedish]: window._env_.DISTRICT_DASH_URL,
    [APP_LOCALE["english-british"]]: window._env_.DISTRICT_DASH_INTERNATIONAL_URL,
    [APP_LOCALE.US]: window._env_.DISTRICT_DASH_INTERNATIONAL_URL,
};

export const LOCALE_STUDENTS_ENV_CONFIG = {
    [APP_LOCALE.swedish]: window._env_.STUDENTS_WEB_URL,
    [APP_LOCALE["english-british"]]: window._env_.STUDENTS_WEB_INTERNATIONAL_URL,
    [APP_LOCALE.US]: window._env_.STUDENTS_WEB_INTERNATIONAL_URL,
};

export const LOCALE_AGREEMENT_LINK_CONFIG = {
    [APP_LOCALE.swedish]: window._env_.MATTEAPPEN_AGREEMENTS_URL,
    [APP_LOCALE["english-british"]]: window._env_.MAGMAMATH_AGREEMENTS_UK_URL,
    [APP_LOCALE.US]: window._env_.MAGMAMATH_AGREEMENTS_URL,
};
