import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './styles.scss';

import { STUDENT, TEACHER, STUDENT_TEXT, TEACHER_TEXT, SIGN_UP, ACCOUNT_DELETED_PARAM } from '../../constants';
import { getParamsFromURL } from '../../helpers';
import { showErrorToast } from '../../constants/toasts';
import AuthForm from '../AuthForm';
import BackButton from '../BackButton';
import ManualRegisterButton from './ManualRegisterButton';
import SSOButtons from '../SSOButtons';

const Register = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [hideSSO, setHideSSO] = useState(false);

    useEffect(() => {
        const params = getParamsFromURL();

        if (Number(params?.deleted) === ACCOUNT_DELETED_PARAM) {
            showErrorToast({ message: t('softDeletedAccountText'), toastId: new Date().getTime() });
            return;
        }

        if (!params.type) {
            return;
        }

        params?.userId && setHideSSO(true);
        const SSOUserType = Number(params.type);
        const userType = SSOUserType === STUDENT ?
            STUDENT_TEXT : SSOUserType === TEACHER ?
            TEACHER_TEXT : '';

        history.push({
            pathname: `${url}/${userType || 'user-select'}`,
            search: window.location.search,
        });
    }, []);

    const onBackButtonPress = (e) => {
        e.preventDefault();
        history.goBack();
    };

    return (
        <div className="signup-form-container">
            <AuthForm type={SIGN_UP}>
                <BackButton onClick={onBackButtonPress} />
                <div className="signup-variants-container">
                    {!hideSSO && (
                        <div className='sso-buttons-container'>
                            <SSOButtons />
                        </div>
                    )}
                    <ManualRegisterButton />
                </div>
                <div className="go-to-login-page">
                    <span className='go-to-login-title'>{t('gotAnAccountText')}</span>
                    <a onClick={onBackButtonPress} className='go-to-login-title link-to-login-page'>{t('gotAnAccountSignInText')}</a>
                </div>
            </AuthForm>
        </div>
    );
};

export default Register;