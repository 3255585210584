import React from 'react';

import { STUDENT_TEXT, TEACHER_TEXT } from '../../../constants';
import UserTypeSelector from '../../../components/Register/UserTypeSelector';
import { useHistory, useRouteMatch } from 'react-router-dom';

const SelectUser = () => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const basePath = path.replace('/user-select', '');

    const onUserTypeClick = (userType) => {
        history.push({
            pathname: `${basePath}/${userType}`,
            search: window.location.search
        });
    };

    return (
        <UserTypeSelector
            onStudentSelect={() => onUserTypeClick(STUDENT_TEXT)}
            onTeacherSelect={() => onUserTypeClick(TEACHER_TEXT)}
        />
    );
};

export default SelectUser;