import React from 'react';

import './styles.scss';

import SkolfedButton from './SkolfedButton';
import MicrosoftButton from './MicrosoftButton';
import GoogleButton from './GoogleButton';
import CleverButton from './CleverButton';
import ClassLinkButton from './ClassLink';
import { APP_LOCALE } from '../../features/localisation/contstants';
import { appLocale } from '../../features/localisation/helpers';

const SSOButtons = () => {
    if (!appLocale) return null;

    return (
        <>
            {appLocale === APP_LOCALE.swedish && <SkolfedButton />}
            <MicrosoftButton locale={appLocale}/>
            <GoogleButton locale={appLocale}/>
            {appLocale === APP_LOCALE.US && <CleverButton locale={appLocale}/>}
            {appLocale === APP_LOCALE.US && <ClassLinkButton locale={appLocale}/>}
        </>
    );
};

export default SSOButtons;
