import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();

    const classCodeBoxObject = {
        required: true,
        error: true,
        validator: {
            structureRegEx: /^\d+$/,
            structureError: true
        }
    };

    const stateSchema = {
        firstName: { value: '', error: '', touched: false },
        lastName: { value: '', error: '', touched: false },
        username: { value: '', error: '', touched: false },
        password: { value: '', error: '', touched: false },
        box1: { value: '', error: '', touched: false },
        box2: { value: '', error: '', touched: false },
        box3: { value: '', error: '', touched: false },
        box4: { value: '', error: '', touched: false },
        box5: { value: '', error: '', touched: false },
        box6: { value: '', error: '', touched: false }
    };

    const validationStateSchema = {
        firstName: {
            required: true,
            error: t('requiredFieldText')
        },
        lastName: {
            required: true,
            error: t('requiredFieldText')
        },
        username: {
            required: true,
            error: t('requiredFieldText'),
            validator: {
                lengthRegEx: /.{4,}/,
                structureRegEx: /^[\w-.@]{4,}\s*$/,
                lengthError: t('validateUsernameLengthText'),
                structureError: t('validateUsernameStructureText')
            }
        },
        password: {
            required: true,
            error: t('requiredFieldText'),
            validator: {
                lengthRegEx: /.{6,}/,
                lengthError: t('validatePasswordLengthText')
            }
        },
        box1: classCodeBoxObject,
        box2: classCodeBoxObject,
        box3: classCodeBoxObject,
        box4: classCodeBoxObject,
        box5: classCodeBoxObject,
        box6: classCodeBoxObject
    };

    return { stateSchema, validationStateSchema };
};