import React from 'react';
import { components } from 'react-select';

import './styles.scss';

const { Control } = components;

const ControlOption = ({ children, ...props}) => {
    return (
        <div className="selector">
            {!props.isDisabled && <span className="selector-title">{props.selectProps?.selectTitle}</span>}
            <Control {...props}>
                {children}
            </Control>
        </div>
    )
};

export default ControlOption;