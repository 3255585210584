import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { COLORS } from "@magmamath/ui";

import FormInput from "../index";
import { PASSWORD_FIELD } from "../../../../constants/fieldNames";

const PASSWORD_LOOKING_ICON_SIZE = 22;

const PasswordInput = ({ value, touched, error, onChange }) => {
    const { t } = useTranslation();
    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
        <FormInput
            placeholder={t("passwordText")}
            name={PASSWORD_FIELD}
            value={value}
            touched={touched}
            valid={touched && !Boolean(error)}
            invalid={touched && Boolean(error)}
            onPress={onChange}
            error={error}
            type={isShowPassword ? "text" : "password"}
            Icon={
                value &&
                (isShowPassword ? (
                    <AiOutlineEye
                        size={PASSWORD_LOOKING_ICON_SIZE}
                        fill={COLORS.NEUTRAL_10}
                    />
                ) : (
                    <AiOutlineEyeInvisible
                        size={PASSWORD_LOOKING_ICON_SIZE}
                        fill={COLORS.NEUTRAL_10}
                    />
                ))
            }
            toggle={() => setIsShowPassword(!isShowPassword)}
        />
    );
};

export default PasswordInput;
