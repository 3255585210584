import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const FormInput = (props) => {
    const { Icon } = props;
    return (
        <div className="form-input-container">
            {props.value && <span className={`form-input-title ${!props.valid ? 'invalid' : ''}`}>{props.placeholder}</span>}
            <input
                className={classNames({
                    'form-input': true,
                    'touched': props.touched,
                    'valid': props.touched && props.valid,
                    'invalid': props.touched && !props.valid,
                    'filled': props.value
                })}
                placeholder={props.placeholder}
                name={props.name}
                onChange={props.onPress}
                type={props.type}
                value={props.value}
                disabled={props.disabled}
                autoFocus={props.autofocus}
            />
            {Icon && (
                <div className='show-password-icon' onClick={props.toggle}>
                    {Icon}
                </div>
            )}
            <div className="form-input-error-message">
                {props.error}
            </div>
        </div>
    );
};

export default FormInput;