import React, {useEffect, useRef} from 'react';

import './styles.scss';

import { CLASS_CODE_LENGTH } from '../../../constants';
import ClassCodeItem from './ClassCodeItem';

const ClassCode = (props) => {
    const ref = useRef();

    const onChange = (e) => {
        if (e.target.value.length > 1) {
            return setFocusToNextBox();
        }

        if (e.target.value.length === 1) {
            props.onChange(e);
            return setFocusToNextBox();
        }
    };

    const findPreviousNotFocusedBox = (box) => {
        const prevBox = box.previousSibling;
        if (!prevBox) {
            return;
        }
        if (!prevBox.value) {
            return prevBox;
        }

        return findPreviousNotFocusedBox(prevBox);
    };

    const findNextNotFocusedBox = (box) => {
        const nextBox = box.nextSibling;
        if (!nextBox) {
            return;
        }
        if (!nextBox.value) {
            return nextBox;
        }

        return findNextNotFocusedBox(nextBox);
    };

    const setFocusToEmptyBox = (currentBox) => {
        const nextBox = findNextNotFocusedBox(currentBox);
        if (nextBox) {
            nextBox.focus();
            return;
        }

        const previousBox = findPreviousNotFocusedBox(currentBox);
        if (!previousBox) {
            currentBox.blur();
            return;
        }

        previousBox.focus();
    };

    const setFocusToNextBox = () => {
        const boxes = Array.from(ref.current.children);
        const activeElement = document.activeElement;
        const boxWithFocus = boxes.find(box => box === activeElement);
        if (boxWithFocus?.value?.length > 0) {
            setFocusToEmptyBox(boxWithFocus);
        }
    };

    const renderBoxes = () => {
        const boxes = [];
        for (let i = 1; i < (CLASS_CODE_LENGTH + 1); i++) {
            boxes.push(
                <ClassCodeItem
                    key={`box-item-${i}`}
                    name={`box${i}`}
                    value={props.classCode[`box${i}`].value}
                    error={props.classCode[`box${i}`].touched && props.classCode[`box${i}`].error}
                    onKeyDown={moveToBox}
                    onChange={onChange}
                />
            )
        }

        return boxes;
    };
    
    const moveToBox = (event) => {
        const boxes = Array.from(ref.current.children);
        const activeElement = document.activeElement
        const currentFocusedIndex = boxes.findIndex(box => box.name === activeElement.name) ?? 0

        const keyMap = {
            ArrowRight: () => {
                event.preventDefault()
                boxes[currentFocusedIndex + 1]?.focus()
            },
            ArrowLeft: () => {
                event.preventDefault()
                boxes[currentFocusedIndex - 1]?.focus()
            },
            Backspace: () => {
                if (!event.target.value) {
                    return boxes[currentFocusedIndex - 1]?.focus()
                }

                props.onChange({
                    target: {
                        name: event.target.name,
                        value: ''
                    }
                });
            }
        };

        return keyMap[event.key]?.();
    }

    useEffect(() => {
        const onClassCodePaste = (event) => {
            const value = event.clipboardData.getData('text');
            const classCodeArray = value.split('').slice(0, CLASS_CODE_LENGTH);

            for (let i = 0; i < CLASS_CODE_LENGTH; i++) {
                onChange({
                    target: {
                        value: classCodeArray[i] ?? ' ',
                        name: `box${i + 1}`
                    }
                })
            }

            const boxes = Array.from(ref.current.children);
            // Workaround for focusing last input after pasting
            setTimeout(() => {
                boxes[classCodeArray.length - 1]?.focus()
            }, 0)
        };

        window.addEventListener('paste', onClassCodePaste);

        return () => {
            window.removeEventListener('paste', onClassCodePaste);
        };
    }, [onChange]);

    return (
        <div className="class-code-inputs" ref={ref}>
            {renderBoxes()}
        </div>
    );
};

export default ClassCode;