import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

import { USERNAME_FIELD } from '../../../constants/fieldNames';
import FormInput from '../../AuthForm/FormInput';
import PasswordInput from '../../AuthForm/FormInput/PasswordInput';
import FormButton from '../../AuthForm/FormButton';

const LoginForm = ({ username, password, handleOnChange, handleOnSubmit, disableButton }) => {
    const { t } = useTranslation();

    return (
        <div className="login-form">
            <FormInput
                placeholder={t('usernameOrEmailText')}
                name={USERNAME_FIELD}
                value={username.value}
                touched={username.touched}
                valid={username.touched && !Boolean(username.error)}
                invalid={username.touched && Boolean(username.error)}
                onPress={handleOnChange}
                error={username.error}
                autofocus={true}
            />
            <PasswordInput
                value={password.value}
                touched={password.touched}
                error={password.error}
                onChange={handleOnChange}
            />
            <div className="signin-button-container">
                <FormButton
                    title={t('signInText')}
                    type="signin"
                    onClick={handleOnSubmit}
                    disable={disableButton}
                />
            </div>
        </div>
    );
};

export default LoginForm;