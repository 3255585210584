export const CLASS_CODE_LENGTH = 6;

export const TEACHER = 1;
export const STUDENT = 2;
export const DISTRICT_DASH = 5;
export const TEACHER_TEXT = 'teacher';
export const STUDENT_TEXT = 'student';

export const DEFAULT_DATA_LIMIT = 20;

export const FIRST_PAGE = 1;
export const SECOND_PAGE = 2;

export const SIGN_IN = 1;
export const SIGN_UP = 2;

export const COMPARE_APP_VERSION_TIME = 600000;

export const REGULAR_AUTH = 1;
export const SKOLFED_AUTH = 2;

export const EMAIL_VALIDATOR = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\s*$/;

export const GOOGLE = 1;
export const MICROSOFT = 2;
export const CLEVER = 3;
export const CLASS_LINK = 4;

export const ACCOUNT_DELETED_PARAM = 1;

export const AUTH_ERROR_CODE = 1;
