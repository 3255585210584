import React from 'react';
import { components } from 'react-select';

import './styles.scss';

const { Option } = components;

const IconOption = (props) => {
    const address = props.data.address;

    return (
        <Option {...props}>
            <div className='option-container'>
                <div className='option-info'>
                    {props.data.icon && (
                        <img src={props.data.icon} style={{width: 30, paddingRight: 10}} alt={props.data.label}/>
                    )}
                    {props.data.label}
                </div>
                {address && (
                    <div className='option-additional-info'>
                        {address?.address_line_1 && <span>{address.address_line_1}, </span>}
                        {address?.city && <span>{address.city}, </span>}
                        {address?.zip_code && <span>{address.zip_code}</span>}
                    </div>
                )}
            </div>
        </Option>
    )
};

export default IconOption;