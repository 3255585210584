import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEng from './locales/en/translation';
import translationSwe from './locales/sw/translation';
import translationGb from './locales/gb/translation';

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        react: {
            useSuspense: false
        },
        debug: false,
        fallbackLng: 'en',
        cleanCode: true,

        resources: {
            en: {
                translations: translationEng
            },
            sv: {
                translations: translationSwe
            },
            gb: {
                translations: translationGb
            }
        },
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
