import { COLORS } from '@magmamath/ui'

const CheckIcon = ({ color = COLORS.PRIMARY_BLUE, size = 15, strokeWidth = "3" }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        d="M1.73 12.91l6.37 6.37L22.79 4.59"
      />
    </svg>
  )
}

export default CheckIcon
