import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from 'react-router-dom';

import KeyIcon from "../../../ui/icons/KeyIcon";

import LongFormButton from "../../LongFormButton";

const ManualRegisterButton = () => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { t } = useTranslation();

    const onClick = () => {
        history.push(`${url}/user-select`);
    };

    return (
        <LongFormButton
            Icon={KeyIcon}
            title={t("usernameAndPasswordText")}
            onClick={onClick}
        />
    );
};

export default ManualRegisterButton;
