import React, { useState, useEffect } from 'react';
import { useIntercom } from "react-use-intercom";

import { STUDENT, FIRST_PAGE, REGULAR_AUTH, SKOLFED_AUTH, SECOND_PAGE, EMAIL_VALIDATOR, SIGN_UP } from '../../../constants';
import { signAccordingToAuthType } from '../../../helpers/apiCallHelper';
import { userParamsFromURL } from '../../../helpers/users/userParamsHelper';
import { onNextButtonPress, checkIsUserAlreadyExistsInClass } from '../../../helpers/users';
import checkIsClassCodeValid from '../../../helpers/checkIsClassCodeValid';
import createRegisterFormSchema from '../../../helpers/users/studentRegisterFormSchema';
import StudentData from './StudentData';
import StudentSettings from './StudentSettings';

import useForm from '../../../hooks/useForm';
import useEnterPress from '../../../hooks/useEnterPress';
import { appLocale } from '../../../features/localisation/helpers';

const RegisterStudent = ({ params }) => {
    const { boot, shutdown } = useIntercom()
    const [page, setPage] = useState(FIRST_PAGE);
    const [authType, setAuthType] = useState(REGULAR_AUTH);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        shutdown()
        return () => boot()
    }, [boot, shutdown])

    const getBoxesFromState = () => {
        const classCodeObject = {};
        for (let i = 1; i < 7; i++) {
            if (state[`box${i}`] !== undefined) {
                classCodeObject[`box${i}`] = state[`box${i}`];
            }
        }

        return classCodeObject;
    };

    const submitButtonClick = async (e) => {
        const classCodeObject = getBoxesFromState();
        const fullClassCode = Object.values(classCodeObject).reduce((previousValue, currentValue) => {
            return previousValue + currentValue.value;
        }, '');

        const isValid = await checkIsClassCodeValid(fullClassCode);
        if (!isValid) {
            return;
        }

        const { firstName, lastName, username, password } = state;
        const clearedUsername = username.value?.trim();
        const email = (authType === SKOLFED_AUTH || EMAIL_VALIDATOR.test(clearedUsername)) ?
            clearedUsername : undefined;

        if (authType === REGULAR_AUTH && await checkIsUserAlreadyExistsInClass(fullClassCode, firstName.value, lastName.value)) {
            return;
        }

        const body = {
            firstName: firstName.value,
            lastName: lastName.value,
            username: clearedUsername,
            email: email,
            password: password.value,
            type: STUDENT,
            invitationalCode: fullClassCode
        };

        await signAccordingToAuthType({ authType, body, params, setDisableButton, locale: appLocale });
    };

    const { stateSchema, validationStateSchema } = createRegisterFormSchema();
    const { state, handleOnChange, handleOnSubmit, checkValidity, setStateField } = useForm({ stateSchema, validationSchema: validationStateSchema, callback: submitButtonClick, signType: SIGN_UP });

    useEnterPress(state, authType, params?.email, setStateField, setPage)

    useEffect(() => {
        if (!params || !Object.keys(params).length) {
            return;
        }

        userParamsFromURL(params, state, setStateField, checkValidity, () => setPage(SECOND_PAGE));
        setAuthType(SKOLFED_AUTH);
    }, [params]);

    return (
        <>
            {page === FIRST_PAGE ?
                (
                    <StudentData
                        state={state}
                        onChange={handleOnChange}
                        onNextButtonPress={() => onNextButtonPress({ authType, username: (state.username.value)?.trim(), email: params?.email, setField: setStateField, callback: () => setPage(SECOND_PAGE) })}
                    />
                ) : (
                    <StudentSettings
                        classCode={getBoxesFromState()}
                        onChange={handleOnChange}
                        onSubmit={handleOnSubmit}
                        onBackButtonPress={() => setPage(FIRST_PAGE)}
                        disableButton={disableButton}
                    />
                )
            }
        </>
    );
};

export default RegisterStudent;
