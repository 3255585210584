import React from 'react';

import { MICROSOFT } from '../../../constants';
import { getSSOAuthUrl } from '../../../helpers';
import microsoft from '../../../assets/images/microsoft.png';
import SSOButton from '../SSOButton';

const MicrosoftButton = ({locale}) => {
    return <SSOButton href={getSSOAuthUrl(MICROSOFT, locale)} image={microsoft} name='Microsoft' />;
};

export default MicrosoftButton;