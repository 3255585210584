import React from 'react';

import { CLASS_LINK } from '../../../constants';
import { getSSOAuthUrl } from '../../../helpers';
import classLink from '../../../assets/images/classlink.png';
import SSOButton from '../SSOButton';

const ClassLinkButton = ({locale}) => {
    return <SSOButton href={getSSOAuthUrl(CLASS_LINK, locale)} image={classLink} name='ClassLink' />;
};

export default ClassLinkButton;
