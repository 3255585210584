import axios from 'axios';
import i18next from 'i18next';

import { showErrorToast } from '../constants/toasts';
import logger from '../logger';
import { writeLogg } from './logger';
import {errorCodeMessages} from '../constants/errorCodeMessages';
import {CODE_NOT_FOUND} from '../constants/customErrorMessages';
import { logglyMessage } from '../constants/logglyMessageTypes';

export default async (classCode, setLoadingCallback) => {
    try {
        setLoadingCallback && setLoadingCallback(true);
        const response = await axios.get(`${window._env_.API_URL}/classes/code/${classCode}`);
        setLoadingCallback && setLoadingCallback(false);
        if (!response?.data?.valid) {
            logger.push({
                level: logglyMessage.error,
                message: "Invalid class code"
            });

            showErrorToast({ message: i18next.t('classCodeNotFoundText'), toastId: i18next.t('classCodeNotFoundText') });
            return false;
        }

        return true;
    } catch (err) {
        setLoadingCallback && setLoadingCallback(false);
        writeLogg(err);

        showErrorToast({ message: err.response ? i18next.t(`${(errorCodeMessages[err.response.data] || CODE_NOT_FOUND)}`) : err.message, toastId: err.response ? err.response.data : err.message });
    }
};