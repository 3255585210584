const UKFlagIcon = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_261)">
            <path d="M31.9999 63.6483C49.4788 63.6483 63.6483 49.4788 63.6483 31.9999C63.6483 14.521 49.4788 0.351562 31.9999 0.351562C14.521 0.351562 0.351562 14.521 0.351562 31.9999C0.351562 49.4788 14.521 63.6483 31.9999 63.6483Z" fill="#346DA6"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M57.7973 13.6622C56.772 12.2223 55.629 10.8719 54.3826 9.62505L54.3749 9.6174C53.128 8.37094 51.7776 7.22802 50.3377 6.20264L6.20264 50.3377C7.22802 51.7776 8.37094 53.128 9.6174 54.3749L9.62505 54.3826C10.8719 55.629 12.2223 56.772 13.6622 57.7973L57.7973 13.6622Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.3377 57.7973C51.7776 56.772 53.128 55.629 54.3749 54.3826L54.3826 54.3749C55.629 53.128 56.772 51.7776 57.7973 50.3377L13.6622 6.20264C12.2223 7.22802 10.8719 8.37094 9.62505 9.6174L9.6174 9.62505C8.37094 10.8719 7.22802 12.2223 6.20264 13.6622L50.3377 57.7973Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M56.7167 51.7682C55.9819 52.6857 55.1972 53.5614 54.3665 54.3911L32.1416 32.1662L34.6281 29.6797L56.7167 51.7682Z" fill="#CA4638"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2318 56.7163C11.3143 55.9816 10.4386 55.1968 9.60889 54.3661L33.592 30.383L36.0786 32.8695L12.2318 56.7163Z" fill="#CA4638"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M51.7688 7.28375C52.6863 8.0185 53.5619 8.80327 54.3917 9.63393L33.925 30.1006L31.4385 27.6141L51.7688 7.28375Z" fill="#CA4638"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.28369 12.2318C8.01844 11.3143 8.80321 10.4387 9.63387 9.60891L29.7489 29.7239L27.2623 32.2104L7.28369 12.2318Z" fill="#CA4638"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M63.2107 37.2745C63.4985 35.5592 63.6483 33.797 63.6483 31.9998C63.6483 30.2027 63.4985 28.4405 63.2107 26.7251H0.789145C0.501358 28.4405 0.351562 30.2027 0.351562 31.9998C0.351562 33.797 0.501358 35.5592 0.789145 37.2745H63.2107Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2745 63.2107C35.5592 63.4985 33.797 63.6483 31.9998 63.6483C30.2027 63.6483 28.4405 63.4985 26.7251 63.2107V0.789145C28.4405 0.501358 30.2027 0.351562 31.9998 0.351562C33.797 0.351562 35.5592 0.501358 37.2745 0.789145V63.2107Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M63.4551 35.5169C63.5827 34.3623 63.6483 33.189 63.6483 32.0004C63.6483 30.8118 63.5827 29.6385 63.4551 28.4839H0.544719C0.417086 29.6385 0.351562 30.8118 0.351562 32.0004C0.351562 33.189 0.417086 34.3623 0.544719 35.5169H63.4551Z" fill="#CA4638"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5164 63.4551C34.3618 63.5827 33.1885 63.6483 31.9999 63.6483C30.8113 63.6483 29.638 63.5827 28.4834 63.4551V0.544719C29.638 0.417086 30.8113 0.351562 31.9999 0.351562C33.1885 0.351562 34.3618 0.417086 35.5164 0.544719V63.4551Z" fill="#CA4638"/>
        </g>
        <defs>
            <clipPath id="clip0_2301_261">
            <rect width="64" height="64" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default UKFlagIcon;