import { APP_LOCALE } from "../features/localisation/contstants";

export const CODE_NOT_FOUND = 'defaultCustomErrorText';
export const USERNAME_EXISTS = 'usernameAlreadyExistsText';
export const EMAIL_EXISTS = 'emailAlreadyExistsText';

export const CLEVER_AUTH_ERROR_MESSAGE = 'Clever login failed. No account was created. Please contact the support';

export const USER_NOT_ENABLED_ERROR = {
    [APP_LOCALE.swedish]: 'Ditt konto är inaktiverat, vänligen kontakta kundtjänst',
    [APP_LOCALE["english-british"]]: 'Your account is inactive. Please contact support'
};
