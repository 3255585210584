import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import TagManager from "react-gtm-module";
import { IntercomProvider } from "react-use-intercom";
import history from './history';

import "bootstrap/dist/css/bootstrap.min.css";
import "@magmamath/ui/dist/variables.scss";

import i18n from "./i18n";
import App from "./containers/App";
import {
    setAPIURLSAccordingToDomain,
    redirectAlreadySignedUser,
    preventShrinkAndroidDevice,
} from "./helpers";
import { appLocale } from "./features/localisation/helpers";
import { BASE_ROUTE_LOCALE } from "./features/localisation/configs";
import { setLocaleToTranslation } from "./features/localisation/helpers/setUpTranlsation";
import { APP_LOCALE } from "./features/localisation/contstants";

preventShrinkAndroidDevice();

setAPIURLSAccordingToDomain(appLocale);

if (appLocale !== APP_LOCALE['english-british']) {
    const tagManagerArgs = {
        gtmId: appLocale === APP_LOCALE.swedish
            ? window._env_.GOOGLE_TAG_MATTEAPPEN
            : window._env_.GOOGLE_TAG_MAGMAMATH,
    };

    TagManager.initialize(tagManagerArgs);
}

redirectAlreadySignedUser(appLocale);

setLocaleToTranslation(appLocale);

ReactDOM.render(
    <BrowserRouter history={history} basename={BASE_ROUTE_LOCALE[appLocale]}>
        <I18nextProvider i18n={i18n}>
            <IntercomProvider appId={window._env_.INTERCOM_KEY}>
                <App />
            </IntercomProvider>
        </I18nextProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
