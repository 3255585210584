import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

import StudentIcon from "../../../ui/icons/StudentIcon";
import TeacherIcon from "../../../ui/icons/TeacherIcon";

import CardWrapper from "../../CardWrapper";
import LongFormButton from "../../LongFormButton";

const UserTypeSelector = (props) => {
    const { t } = useTranslation();

    return (
        <CardWrapper>
            <span className="student-or-teacher-text">
                {t("studentOrTeacherText")}
            </span>
            <div className="user-select-buttons-container">
                <LongFormButton
                    title={t("studentText")}
                    Icon={StudentIcon}
                    onClick={props.onStudentSelect}
                />
                <LongFormButton
                    title={t("teacherText")}
                    Icon={TeacherIcon}
                    onClick={props.onTeacherSelect}
                />
            </div>
        </CardWrapper>
    );
};

export default UserTypeSelector;
