import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';

import 'react-toastify/dist/ReactToastify.css';

import './styles.scss';

import ClosePopupButton from '../components/ClosePopupButton';
import Logo from '../components/Logo';
import LanguageSwitch from '../features/localisation/components/LanguageSwitch';
import Sign from './Sign';

const App = () => {
    const { boot } = useIntercom();

    useEffect(() => {
        boot();
    }, [boot]);

    return (
        <>
            <Logo />
            <Sign />
            <ToastContainer
                closeButton={<ClosePopupButton />}
                newestOnTop={true}
                pauseOnFocusLoss={false}
                limit={1}
            />
            <LanguageSwitch />
        </>
    );
};

export default App;