import React from 'react';

import './styles.scss';

const FormButton = (props) => {
    return (
        <button
            className={`form-button ${props.type}`}
            onClick={props.onClick}
            disabled={props.disable}
        >
            {props.title}
        </button>
    );
};

export default FormButton;