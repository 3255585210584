import {PASSWORD_FIELD, USERNAME_FIELD, EMAIL, FIRST_NAME_FIELD, LAST_NAME_FIELD} from '../../constants/fieldNames';
import {VALUE_FIELD, DISABLE_FIELD, ERROR_FIELD, TOUCHED_FIELD } from '../../constants/fieldTypes';
import { generatePassword, disableCreateAccountNextButton } from '../logicHelper';

export const userParamsFromURL = (params, state, setStateField, checkValidity, callback) => {
    const parsedState = {};

    for (let key in params) {
        if (key.toLowerCase() === FIRST_NAME_FIELD.toLowerCase()) {
            setStateField(FIRST_NAME_FIELD, VALUE_FIELD, params[key])
            setStateField(FIRST_NAME_FIELD, TOUCHED_FIELD, true)
        }

        if (key.toLowerCase() === LAST_NAME_FIELD.toLowerCase()) {
            setStateField(LAST_NAME_FIELD, VALUE_FIELD, params[key])
            setStateField(LAST_NAME_FIELD, TOUCHED_FIELD, true)
        }

        if (state.hasOwnProperty(key)) {
            if (key === USERNAME_FIELD) {
                setStateField(key, DISABLE_FIELD, true);
                const password = generatePassword();
                setStateField(PASSWORD_FIELD, VALUE_FIELD, password);
                setStateField(PASSWORD_FIELD, DISABLE_FIELD, true);

                parsedState[USERNAME_FIELD] = { [DISABLE_FIELD]: true };
                parsedState[PASSWORD_FIELD] = { [VALUE_FIELD]: password, [DISABLE_FIELD]: true };
            }

            setStateField(key, VALUE_FIELD, params[key]);
            const { error, touched } = checkValidity(key, params[key]);
            setStateField(key, ERROR_FIELD, error);
            setStateField(key, TOUCHED_FIELD, touched);

            parsedState[key] = { ...parsedState[key], [VALUE_FIELD]: params[key], [ERROR_FIELD]: error, [TOUCHED_FIELD]: touched };
        }
    }

    if (!params[USERNAME_FIELD]) {
        setStateField(USERNAME_FIELD, VALUE_FIELD, params[EMAIL] || '');
        setStateField(USERNAME_FIELD, DISABLE_FIELD, true);
        const { error, touched } = checkValidity(USERNAME_FIELD, params[EMAIL]);
        setStateField(USERNAME_FIELD, ERROR_FIELD, error);
        setStateField(USERNAME_FIELD, TOUCHED_FIELD, touched);
        const password = generatePassword();
        setStateField(PASSWORD_FIELD, VALUE_FIELD, password);
        setStateField(PASSWORD_FIELD, DISABLE_FIELD, true);

        parsedState[USERNAME_FIELD] = { [VALUE_FIELD]: params[EMAIL] || '', [DISABLE_FIELD]: true, [ERROR_FIELD]: error, [TOUCHED_FIELD]: touched };
        parsedState[PASSWORD_FIELD] = { [VALUE_FIELD]: password, [DISABLE_FIELD]: true };
    }

    !disableCreateAccountNextButton(parsedState) && callback && callback();
};
