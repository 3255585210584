import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

import QRCodeIcon from "../../../ui/icons/QRCodeIcon";

const QRCodeButton = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <div className="sso-button" onClick={onClick}>
            <div className="sso-button-icon">
                <QRCodeIcon />
            </div>
            <span className="sso-button-title">{t("QRCodeText")}</span>
        </div>
    );
};

export default QRCodeButton;
