import React from "react";
import { COLORS } from "@magmamath/ui";

const QRCodeIcon = ({
    color = COLORS.NEUTRAL_10,
    secondaryColor = COLORS.NEUTRAL_1,
}) => (
    <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3488_44690)">
            <path
                d="M1.68183 13.4545C0.672747 13.4545 0 12.7818 0 11.7727V5.04543C0 2.18634 2.18634 0 5.04543 0H11.7727C12.7818 0 13.4545 0.672747 13.4545 1.68183C13.4545 2.69092 12.7818 3.36367 11.7727 3.36367H5.04543C4.03635 3.36367 3.3636 4.03642 3.3636 5.0455V11.7728C3.36367 12.7818 2.69092 13.4545 1.68183 13.4545Z"
                fill={color}
            />
            <path
                d="M35.3182 13.4545C34.3091 13.4545 33.6363 12.7818 33.6363 11.7727V5.04543C33.6363 4.03635 32.9636 3.3636 31.9545 3.3636H25.2272C24.2182 3.3636 23.5454 2.69085 23.5454 1.68177C23.5454 0.672679 24.2182 0 25.2272 0H31.9545C34.8136 0 36.9999 2.18634 36.9999 5.04543V11.7727C37 12.7818 36.3273 13.4545 35.3182 13.4545Z"
                fill={color}
            />
            <path
                d="M11.7727 37.0001H5.04543C2.18634 37.0001 0 34.8137 0 31.9546V25.2274C0 24.2183 0.672747 23.5455 1.68183 23.5455C2.69092 23.5455 3.36367 24.2183 3.36367 25.2274V31.9546C3.36367 32.9637 4.03642 33.6365 5.0455 33.6365H11.7728C12.7819 33.6365 13.4546 34.3092 13.4546 35.3183C13.4545 36.3273 12.7818 37.0001 11.7727 37.0001Z"
                fill={color}
            />
            <path
                d="M31.9545 37.0001H25.2272C24.2182 37.0001 23.5454 36.3274 23.5454 35.3183C23.5454 34.3092 24.2182 33.6365 25.2272 33.6365H31.9545C32.9636 33.6365 33.6363 32.9637 33.6363 31.9546V25.2274C33.6363 24.2183 34.3091 23.5455 35.3182 23.5455C36.3273 23.5455 37 24.2183 37 25.2274V31.9546C36.9999 34.8138 34.8136 37.0001 31.9545 37.0001Z"
                fill={color}
            />
            <path
                d="M15.1362 16.8187H8.40888C7.3998 16.8187 6.72705 16.146 6.72705 15.1369V8.40962C6.72705 7.40053 7.3998 6.72778 8.40888 6.72778H15.1362C16.1452 6.72778 16.818 7.40053 16.818 8.40962V15.1369C16.818 16.146 16.1452 16.8187 15.1362 16.8187ZM10.0907 13.455H13.4544V10.0914H10.0907V13.455Z"
                fill={color}
            />
            <path
                d="M15.1362 30.2732H8.40888C7.3998 30.2732 6.72705 29.6004 6.72705 28.5914V21.8641C6.72705 20.855 7.3998 20.1823 8.40888 20.1823H15.1362C16.1452 20.1823 16.818 20.855 16.818 21.8641V28.5914C16.818 29.6004 16.1452 30.2732 15.1362 30.2732ZM10.0907 26.9095H13.4544V23.5459H10.0907V26.9095Z"
                fill={color}
            />
            <path
                d="M28.5907 16.8187H21.8635C20.8544 16.8187 20.1816 16.146 20.1816 15.1369V8.40962C20.1816 7.40053 20.8544 6.72778 21.8635 6.72778H28.5907C29.5998 6.72778 30.2726 7.40053 30.2726 8.40962V15.1369C30.2726 16.146 29.5998 16.8187 28.5907 16.8187ZM23.5453 13.455H26.909V10.0914H23.5453V13.455Z"
                fill={color}
            />
            <path
                d="M21.8635 25.2278C20.8544 25.2278 20.1816 24.555 20.1816 23.5459V21.8641C20.1816 20.855 20.8544 20.1823 21.8635 20.1823C22.8726 20.1823 23.5453 20.855 23.5453 21.8641V23.5459C23.5453 24.5551 22.8726 25.2278 21.8635 25.2278Z"
                fill={color}
            />
            <path
                d="M28.5907 23.5459H26.9089C25.8998 23.5459 25.2271 22.8732 25.2271 21.8641C25.2271 20.855 25.8998 20.1823 26.9089 20.1823H28.5907C29.5998 20.1823 30.2726 20.855 30.2726 21.8641C30.2726 22.8732 29.5998 23.5459 28.5907 23.5459Z"
                fill={color}
            />
            <path
                d="M28.591 30.2729H23.5456C22.5365 30.2729 21.8638 29.6002 21.8638 28.5911C21.8638 27.582 22.5365 26.9092 23.5456 26.9092H28.591C29.6001 26.9092 30.2729 27.582 30.2729 28.5911C30.2729 29.6002 29.6001 30.2729 28.591 30.2729Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_3488_44690">
                <rect width="37" height="37" fill={secondaryColor} />
            </clipPath>
        </defs>
    </svg>
);

export default QRCodeIcon;
