import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { SIGN_IN, SIGN_UP } from '../../constants';

const AuthForm = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`auth-form-container ${props.type === SIGN_UP ? 'signup' : ''}`}>
            <span className="login-title">{t(props.type === SIGN_IN ? 'loginViaText' : 'createAccountWithText')}</span>
            {props.children}
        </div>
    );
};

export default AuthForm;