import { useCallback, useEffect } from 'react';

import { disableCreateAccountNextButton } from '../helpers/logicHelper';
import { onNextButtonPress } from '../helpers/users';
import { SECOND_PAGE } from '../constants';

const useEnterPress = (state, authType, email, setStateField, setPage) => {
    const onEnterPress = useCallback((e) => {
        if (e.key !== 'Enter') {
            return;
        }

        !disableCreateAccountNextButton(state) && onNextButtonPress({ authType, username: state.username.value, email: email, setField: setStateField, callback: () => setPage(SECOND_PAGE) });
    }, [authType, email, setStateField, state, setPage]);

    useEffect(() => {
        document.addEventListener('keypress', onEnterPress);

        return () => {
            document.removeEventListener('keypress', onEnterPress);
        };
    }, [onEnterPress]);
};

export default useEnterPress;