import React from 'react';

import './styles.scss';

import BackButton from '../BackButton';

import history from '../../history';

const CardWrapper = (props) => {
    const backButtonClick = () => {
        if (props?.onBackButtonPress) {
            props.onBackButtonPress();
            return;
        }

        history.goBack();
    };

    return (
        <div className="card-wrapper">
            <div className="card-container">
                <BackButton onClick={backButtonClick}/>
                {props.children}
            </div>
        </div>
    );
};

export default CardWrapper;