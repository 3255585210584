import React from 'react';
import { IoMdClose } from 'react-icons/io';

import './styles.scss';

const ClosePopupButton = (props) => {
    return (
        <button className="close-popup-button" onClick={props.closeToast}>
            <IoMdClose size={18} />
        </button>
    );
};

export default ClosePopupButton;