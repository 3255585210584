export const ENGLISH = {
    name: 'English',
    key: 'en'
};

export const SWEDEN = {
    name: 'Svenska',
    key: 'sv'
};

export const BRITISH = {
    name: 'British',
    key: 'gb'
}

export const languages = [ENGLISH, SWEDEN, BRITISH];
