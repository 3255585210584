import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Register from '../../components/Register';
import SelectUser from './SelectUser';
import RegistrationFormSelector from '../../components/Register/RegistrationFormSelector';

const RegisterPage = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={Register} />
            <Route exact path={`${path}/user-select`} component={SelectUser} />
            <Route exact path={`${path}/:type`} component={RegistrationFormSelector} />
        </Switch>
    );
};

export default RegisterPage;
