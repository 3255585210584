import React from 'react';

import './styles.scss';

const Loader = (props) => {
    return(
        <div className="loader-container">
            {props.children}
        </div>
    );
};

export default Loader;