import axios from 'axios';
import i18next from 'i18next';

import { STUDENT, SKOLFED_AUTH, REGULAR_AUTH } from '../constants';
import {USERNAME_EXISTS, EMAIL_EXISTS, SWE_USER_NOT_ENABLED, USER_NOT_ENABLED_ERROR} from '../constants/customErrorMessages';
import { errorCodeMessages } from '../constants/errorCodeMessages';
import { ERROR} from '../constants/toasts/toastTypes';
import { USER_TYPE, REDIRECT_URL } from '../constants/localStorageConstants';
import CustomError from '../constants/customError';
import { getUserURLAccordingToLocale, checkIOSDevice } from './index';
import { writeLogg } from './logger';
import { showErrorToast } from '../constants/toasts';
import history from '../history';
import { LOCALE_STUDENTS_ENV_CONFIG } from '../features/localisation/configs';
import { APP_LOCALE } from '../features/localisation/contstants';

const makeApiCall = async (handler) => {
    try {
        return await handler();
    } catch (error) {
        if (error instanceof CustomError) {
            switch (error.type) {
                case ERROR:
                    showErrorToast({ message: i18next.t(`${error.message}`), toastId: error.message });
                    break;
                default:
                    break;
            }

            return i18next.t(`${error.message}`);
        }

        writeLogg(error);
        showErrorToast({ message: error.response ? (i18next.t(`${(errorCodeMessages[error.response.data.code])}`) || error.response.data?.message) : error.message, toastId: error.response ? error.response.data.code : error.message });
    }
};

export const getCountries = ({ page, search }) => {
    const countriesRequest = async () => {
        const response = await axios.get(`${window._env_.API_URL}/countries`, { params: { page, search }});
        const data = response.data;
        const pages = data?.pages;
        const countries = data?._embedded?.countries;
        return { countries, pages };
    };

    return makeApiCall(countriesRequest);
};

export const getMunicipalities = ({ id, offset, search, type = 'countries' }) => {
    const municipalitiesRequest = async () => {
        const response = await axios.get(`${window._env_.API_URL}/${type}/${id}/municipalities`, { params: { sort: 'asc', public: '1', offset, search }});
        return response.data?.items;
    };

    return makeApiCall(municipalitiesRequest);
};

export const getRegions = ({ id, offset, search }) => {
    const regionsRequest = async () => {
        const response = await axios.get(`${window._env_.API_URL}/countries/${id}/regions`, { params: { sort: 'asc', public: '1', offset, search }});
        return response.data?.items;
    };

    return makeApiCall(regionsRequest);
};

export const getSchools = ({ id, offset, search, type = 'municipalities' }) => {
    const schoolsRequest = async () => {
        const response = await axios.get(`${window._env_.API_URL}/${type}/${id}/schools?sort=asc`, { params: { offset, search }});
        return response.data?.items;
    };

    return makeApiCall(schoolsRequest);
};

const redirectUser = (userType, userId, autologinToken, locale) => {
    const redirectUrl = getUserURLAccordingToLocale(userType, locale);

    const urlWithParams = new URL(redirectUrl);
    urlWithParams.searchParams.append('userId', userId);
    urlWithParams.searchParams.append('token', autologinToken);
    urlWithParams.searchParams.append('locale', locale);
    const queryRedirectURL = localStorage.getItem(REDIRECT_URL);
    queryRedirectURL && urlWithParams.searchParams.append('redirectURL', queryRedirectURL);
    if (!urlWithParams.href) {
        return;
    }

    localStorage.removeItem(REDIRECT_URL);
    localStorage.setItem(USER_TYPE, userType);
    window.location.replace(urlWithParams.href);
};

const authUserAndRedirect = async (token, authType, locale) => {
    const authMe = () => {
        return axios.get(`${window._env_.API_URL}/auth/me?fetchCountry=1`, {
            headers: {
                'Authorization': token
            }
        });
    }

    const getAutologinToken = () => {
        return axios.get(`${window._env_.API_URL}/autologin-token`, {
            headers: {
                'Authorization': token
            }
        });
    }

    const authResponse = await authMe();
    if (authResponse.data?.enabled === false && authResponse.data?.municipality?.forceOnboarding) {
        if (locale === APP_LOCALE.US) {
            history.push('/schedule-onboarding');
        } else {
            showErrorToast({ message: USER_NOT_ENABLED_ERROR[locale], toastId: authResponse.data?._id });
        }

        return;
    }
    const userAppLanguage = authResponse.data?.setting.appLanguage;

    const userType = authResponse.data?.type;
    const autologinTokenResponse = await getAutologinToken();
    if (authType === SKOLFED_AUTH && window.ReactNativeWebView) {
        return window.ReactNativeWebView.postMessage(JSON.stringify({ userId: authResponse.data?._id, token, type: userType }));
    }

    redirectUser(userType, authResponse.data?._id, autologinTokenResponse.data?.autologinToken, userAppLanguage || 'en-SE');
};

export const autoSignInUser = ({ userId, userType, autologinToken, locale }) => {
    const baseURL = getUserURLAccordingToLocale(Number(userType), locale);

    const urlWithParams = new URL(baseURL);
    urlWithParams.searchParams.append('userId', userId);
    urlWithParams.searchParams.append('token', autologinToken);

    localStorage.setItem(USER_TYPE, userType);
    if (Number(userType) === STUDENT && checkIOSDevice()) {
        window.location.replace(`${window._env_.REACT_NATIVE_APP_ID}://auth/${userId}/${autologinToken}`);
        window.addEventListener("load", function () {
            setTimeout(() => {
                window.location.replace(urlWithParams.href);
            }, 2000);
        }, false);
        return;
    }

    window.location.replace(urlWithParams.href);
};

const extractExerciseParams = (url) => {
    const exerciseIdRegex = /\/exercises\/(available|archive)(\/(.*))*/gm;
    const arr = exerciseIdRegex.exec(url);
    return { exerciseId: arr?.[2] || null, status: arr?.[1] || null};
}

export const autoExerciseRedirect = ({ redirectUrl, locale }) => {
    if (!redirectUrl) return;

    const { exerciseId, status } = extractExerciseParams(redirectUrl)
    if (!exerciseId) return

    if (checkIOSDevice()) {
        window.location.replace(`${window._env_.REACT_NATIVE_APP_ID}://exercises/${status}/${exerciseId}`);

        setTimeout(() => {
            const envUrl = LOCALE_STUDENTS_ENV_CONFIG[locale] || LOCALE_STUDENTS_ENV_CONFIG[APP_LOCALE.swedish];
            
            window.location.replace(`${envUrl}${redirectUrl}`);
        }, 3000);
    }
};

const signAndAuth = async ({ url, body, authType, locale }) => {
    let response;
    try {
        response = await axios.post(url, body);
    } catch (error) {
        showErrorToast({ message: error.response ? (i18next.t(`${(errorCodeMessages[error.response.data?.code])}`) || error.response.data?.message) : error.message, toastId: error.response ? error.response.data?.code : error.message });
        return;
    }
    const token = response.data.token;
    await authUserAndRedirect(token, authType, locale);
};

export const signIn = (body, locale) => {
    const signRequest = async () => {
        await signAndAuth({ url: `${window._env_.API_URL}/auth`, body, locale })
    };

    return makeApiCall(signRequest);
};

const signUp = (body, locale) => {
    const signUpRequest = async () => {
        await signAndAuth({ url: `${window._env_.API_URL}/users`, body, locale });
    };

    return makeApiCall(signUpRequest);
};

export const signWithQRCode = async (code, locale) => {
    try {
        const response = await axios.post(`${window._env_.API_URL}/qr-codes/login`, {key: code});
        await authUserAndRedirect(response.data?.token, REGULAR_AUTH, locale);
    } catch (error) {
        showErrorToast({ message: error.response ? i18next.t(`${(errorCodeMessages[error.response.data?.code])}`) : error.message, toastId: error.response ? error.response.data?.code : error.message });
    }
};

const skolfedSignUp = (body, authType, locale) => {
    const signUpRequest = async () => {
        await signAndAuth({ url: `${window._env_.API_URL}/users`, body, authType, locale });
    };

    return makeApiCall(signUpRequest);
};

export const signAccordingToAuthType = async ({ authType, body, params, setDisableButton, locale }) => {
    body.appLocale = locale;

    if (authType === SKOLFED_AUTH) {
        params.email && (body.email = params.email);
        body.sourceType = params.sourceType;
        setDisableButton(true);
        await skolfedSignUp(body, authType, locale);
        setDisableButton(false);
        return;
    }

    setDisableButton(true);
    await signUp(body, locale);
    setDisableButton(false);
};

export const authMe = (username) => {
    const apiRequest = async () => {
        const response = await axios.post(`${window._env_.API_URL}/auth/username-check`, { username });
        if (!response.data.available) {
            throw new CustomError({ type: ERROR, message: USERNAME_EXISTS });
        }
    };

    return makeApiCall(apiRequest);
};

export const checkIsEmailExists = async (email) => {
    const apiRequest = async () => {
        const response = await axios.post(`${window._env_.API_URL}/auth/email-check`, { email });
        if (!response.data.available) {
            throw new CustomError({ type: ERROR, message: EMAIL_EXISTS });
        }
    };

    return makeApiCall(apiRequest);
};

export const getSchoolByCode = (schoolCode) => {
    const apiRequest = async () => {
        const response = await axios.get(`${window._env_.API_URL}/schools/code/${schoolCode}`);
        return response?.data;
    };

    return makeApiCall(apiRequest);
};
