import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentForgotPasswordURL } from '../../helpers';
import { showInfoPopup } from '../../constants/toasts';

import UserTypeSelector from '../Register/UserTypeSelector';
import { appLocale } from '../../features/localisation/helpers';

const ForgotPassword = () => {
    const { t } = useTranslation();

    const onTeacherSelect = () => {
        window.location.replace(getCurrentForgotPasswordURL(appLocale));
    };

    const onStudentSelect = () => {
        showInfoPopup({ message: t('forgotPasswordStudentText'), toastId: new Date().getTime() });
    };

    return (
        <UserTypeSelector
            onStudentSelect={onStudentSelect}
            onTeacherSelect={onTeacherSelect}
        />
    );
};

export default ForgotPassword;