import axios from 'axios';
import i18next from 'i18next';

import logger from '../../logger';
import { writeLogg } from '../logger';
import { authMe, checkIsEmailExists } from '../apiCallHelper';
import { USERNAME_FIELD } from '../../constants/fieldNames';
import { ERROR_FIELD } from '../../constants/fieldTypes';
import { SKOLFED_AUTH, EMAIL_VALIDATOR } from '../../constants';
import { logglyMessage } from '../../constants/logglyMessageTypes';
import { showErrorToast, showInfoPopup } from '../../constants/toasts';
import { errorCodeMessages } from '../../constants/errorCodeMessages';
import { CODE_NOT_FOUND } from '../../constants/customErrorMessages';

export const onNextButtonPress = async ({ authType, username, email, setField, callback }) => {
    if (authType === SKOLFED_AUTH) {
        callback();
        return;
    }

    const responseUsernameMessage = await authMe(username);
    if (responseUsernameMessage) {
        setField(USERNAME_FIELD, ERROR_FIELD, responseUsernameMessage);
        return;
    }

    if (EMAIL_VALIDATOR.test(username)) {
        const responseEmailMessageAsUsername = await checkIsEmailExists(username);
        if (responseEmailMessageAsUsername) {
            setField(USERNAME_FIELD, ERROR_FIELD, responseEmailMessageAsUsername);
            return;
        }
    }

    if (email) {
        const responseEmailMessage = await checkIsEmailExists(email);
        if (responseEmailMessage) {
            setField(USERNAME_FIELD, ERROR_FIELD, responseEmailMessage);
            return;
        }
    }

    callback();
};

const getErrorMessageAccordingToCurrentAttempt = (attempt) => {
    const attemptMap = {
        1: 'firstSignupStudentAttempt',
        2: 'secondSignupStudentAttempt',
        3: 'thirdSignupStudentAttempt',
        4: 'fourthSignupStudentAttempt',
        5: 'fifthSignupStudentAttempt',
        'default': 'defaultSignupStudentError'
    };

    return attemptMap[attempt] || attemptMap['default'];
};

export const checkIsUserAlreadyExistsInClass = async (classCode, firstName, lastName) => {
    try {
        const response = await axios.get(`${window._env_.API_URL}/classes/code/${classCode}?action=get-by-class-code`);
        const students = response?.data?.class?.students;
        if (!students || !students.length) {
            return false;
        }

        const existStudent = students.find(student => student.firstName?.toLowerCase() === firstName?.toLowerCase() && student.lastName?.toLowerCase() === lastName?.toLowerCase());
        if (existStudent) {
            logger.push({
                level: logglyMessage.error,
                message: `Can't create student. Student with firstName=${firstName} and lastName=${lastName} already exists in class=${classCode}`
            });

            showInfoPopup({ message: i18next.t(getErrorMessageAccordingToCurrentAttempt(existStudent.source)), toastId: new Date().getTime() });
        }

        return existStudent;
    } catch (err) {
        writeLogg(err);
        showErrorToast({ message: err.response ? i18next.t(`${(errorCodeMessages[err.response.data] || CODE_NOT_FOUND)}`) : err.message, toastId: err.response ? err.response.data : err.message });
    }
};