import React from "react";

const LogoIcon = () => (
    <svg
        width="77"
        height="50"
        viewBox="0 0 77 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 13.8459C1.91154 13.8459 3.64231 14.6213 4.89423 15.8748C6.14769 17.1267 6.92308 18.8574 6.92308 20.769V49.2305H20.7692V20.769C20.7692 9.29822 11.4708 -0.000244141 0 -0.000244141V13.8459ZM27.4531 0.093602C25.0858 0.11091 22.8108 0.526294 20.6954 1.27399C26.2896 2.62629 34.0815 9.81822 34.4854 20.0301C34.5181 20.3032 34.5354 20.5821 34.5354 20.8628V49.3244H48.3815V21.109C48.3815 9.59745 38.9627 0.00706355 27.4531 0.093602ZM55.1454 0.093602C52.7781 0.11091 50.5031 0.526294 48.3877 1.27399C53.9819 2.62629 61.7738 9.81822 62.1777 20.0301C62.2104 20.3032 62.2277 20.5821 62.2277 20.8628V35.4782C62.2277 39.3013 63.7777 42.7628 66.2838 45.2682C68.7896 47.7744 72.2512 49.3244 76.0738 49.3244V21.109C76.0738 9.59745 66.655 0.00706355 55.1454 0.093602Z"
            fill="#fefefe"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.1777 20.0302C61.9896 18.4514 61.2685 17.036 60.1989 15.9683C58.9066 14.674 57.1031 13.8894 55.1166 13.9417C51.3492 14.0417 48.3816 17.2067 48.3816 20.9764V49.3244H34.5354V20.8629C34.5354 15.1264 36.8608 9.93405 40.6181 6.17597C42.7873 4.00712 45.4339 2.31636 48.3877 1.27405C53.9816 2.62636 61.7739 9.81828 62.1777 20.0302"
            fill="url(#paint0_linear_2:76)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5066 15.9683C31.2143 14.674 29.4108 13.8894 27.4243 13.9417C23.657 14.0417 20.6893 17.2067 20.6893 20.9764V49.3244H6.84314V20.8629C6.84314 15.1264 9.16852 9.93405 12.9258 6.17597C15.0951 4.00712 17.7416 2.31636 20.6954 1.27405C26.2893 2.62636 34.0816 9.81828 34.4854 20.0302C34.2974 18.4514 33.5762 17.036 32.5066 15.9683Z"
            fill="url(#paint1_linear_2:76)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2:76"
                x1="62.1777"
                y1="8.0632"
                x2="34.5354"
                y2="8.0632"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#ffffff" stopOpacity="0.01" />
                <stop offset="1" stopColor="#fefefe" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2:76"
                x1="34.4854"
                y1="8.0632"
                x2="6.84314"
                y2="8.0632"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#ffffff" stopOpacity="0.01" />
                <stop offset="1" stopColor="#fefefe" />
            </linearGradient>
        </defs>
    </svg>
);

export default LogoIcon;
