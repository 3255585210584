import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { COLORS } from "@magmamath/ui";

import "./styles.scss";

import { COUNTRY } from "../../../constants/selectorConstants";
import { DEFAULT_DATA_LIMIT } from "../../../constants";
import IconOption from "./Option";
import ControlOption from "./Control";
import DropdownIndicator from "./DropdownIndicator";
import SingleValue from "./SingleValue";

const InputSelector = ({
    options,
    selectedItem,
    selectItem,
    type,
    id,
    disable,
    title,
    limit,
    pullUpData,
    setData,
    isSelectorOpen,
}) => {
    const { t } = useTranslation();

    const [offsetItems, setOffsetItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isOpen, setIsOpen] = useState(isSelectorOpen);
    const [pending, setPending] = useState(false);
    const [pullDataLoader, setPullDataLoader] = useState(false);
    const timeoutRef = useRef(null);
    const inputRef = useRef(null);
    const selectRef = useRef(null);

    useEffect(() => {
        setIsOpen(isSelectorOpen);
    }, [isSelectorOpen]);

    useEffect(() => {
        if (!isOpen || !options) return;
        selectRef.current?.focus();
    }, [options, isOpen]);

    const loadData = useCallback(async () => {
        const body = {};
        if (type === COUNTRY) {
            const page = currentPage + 1;
            if (page > limit) {
                return;
            }

            body.page = page;
            setCurrentPage(page);
        } else {
            const newOffsetItems = offsetItems + DEFAULT_DATA_LIMIT;
            if (newOffsetItems !== limit) {
                return;
            }

            body.id = id;
            body.offset = newOffsetItems;
            setOffsetItems(newOffsetItems);
        }

        setPullDataLoader(true);
        const response = await pullUpData(body);
        setPullDataLoader(false);
        setData([...options, ...response]);
    }, [
        currentPage,
        offsetItems,
        id,
        limit,
        setData,
        options,
        type,
        pullUpData,
    ]);

    const loadDataWithSearch = useCallback(
        async (search) => {
            const response = await pullUpData({ id: id, search });
            let newData;
            if (type === COUNTRY) {
                newData = { data: response.countries, pages: response.pages };
            } else {
                newData = response;
            }

            setData(newData);
        },
        [pullUpData, setData, id, type]
    );

    const onSearchData = useCallback(
        (value) => {
            clearTimeout(timeoutRef.current);
            if (inputRef.current === value) return;
            inputRef.current = value;

            timeoutRef.current = setTimeout(async () => {
                if (!value) {
                    const response = await pullUpData({ id });
                    let newData;
                    if (type === COUNTRY) {
                        newData = {
                            data: response.countries,
                            pages: response.pages,
                        };
                    } else {
                        newData = response;
                    }

                    setData(newData);
                    return;
                }

                await loadDataWithSearch(value);
                if (type === COUNTRY) {
                    setCurrentPage(1);
                    return;
                }

                setOffsetItems(0);
            }, 500);
        },
        [loadDataWithSearch, pullUpData, type, id, setData]
    );

    const onScrollList = useCallback(
        async (event) => {
            const scrollBottom =
                event.target.scrollTop + event.target.offsetHeight ===
                event.target.scrollHeight;

            if (!scrollBottom) {
                return;
            }

            await loadData();
        },
        [loadData]
    );

    useEffect(() => {
        if (!options || !pending) {
            return;
        }

        setPending(false);
    }, [options, pending]);

    const onSelectItem = ({ icon, value, id, dividedBasedOn }) => {
        selectItem({ type, icon, name: value, id, dividedBasedOn });
    };

    const getOptions = () => {
        if (!options?.length) {
            return [];
        }

        return options.map((option) => {
            return {
                label: option.name,
                value: option.name,
                address: option.address,
                icon: option.icon,
                id: option._id,
                dividedBasedOn: option.dividedBasedOn,
            };
        });
    };

    const getSelectedOption = () => {
        if (!selectedItem) {
            return null;
        }

        return {
            label: selectedItem.name,
            value: selectedItem.name,
            icon: selectedItem.icon,
            id: selectedItem.id,
        };
    };

    const getCustomStyles = () => {
        return {
            singleValue: (base) => ({
                ...base,
                display: "flex",
                height: "100%",
            }),
            control: (base) => {
                const borderColor = selectedItem
                    ? COLORS.PRIMARY_GREEN
                    : COLORS.PRIMARY_BLUE;

                return {
                    ...base,
                    backgroundColor: disable
                        ? COLORS.NEUTRAL_3
                        : COLORS.NEUTRAL_1,
                    height: "100%",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: disable ? COLORS.NEUTRAL_3 : `${borderColor}`,
                    borderRadius: "12px",
                    boxShadow: "none",
                    ":active": {
                        borderColor: COLORS.PRIMARY_BLUE,
                    },
                    ":hover": {
                        borderColor: COLORS.PRIMARY_BLUE,
                    },

                    "@media screen and (max-width: 500px)": {
                        borderRadius: "6px",
                    },
                };
            },
            valueContainer: (base) => ({
                ...base,
                height: "100%",
            }),
            menu: (base) => ({
                ...base,
                overflow: "auto",
                zIndex: 2,
            }),
            menuList: (base) => ({
                ...base,
                margin: "5px 0 0 0",
                maxHeight: "200px",
                "::-webkit-scrollbar": {
                    width: "5px",
                    height: "5px",
                },
                "::-webkit-scrollbar-track": {
                    borderRadius: "5px",
                    backgroundColor: COLORS.NEUTRAL_1,
                },
                "::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    backgroundColor: COLORS.NEUTRAL_5,
                },
            }),
            indicatorSeparator: (base) => ({
                ...base,
                backgroundColor: COLORS.NEUTRAL_5,
            }),
            dropdownIndicator: (base) => ({
                ...base,
                color: COLORS.NEUTRAL_5,
                "&:hover": {
                    color: COLORS.NEUTRAL_5,
                },
            }),
            noOptionsMessage: (base) => ({
                ...base,
                color: COLORS.NEUTRAL_5,
            }),
        };
    };

    return (
        <div className="selector-container">
            <Select
                ref={selectRef}
                components={{
                    Option: IconOption,
                    Control: ControlOption,
                    DropdownIndicator,
                    SingleValue,
                }}
                options={getOptions()}
                value={getSelectedOption()}
                onChange={onSelectItem}
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                isDisabled={disable}
                menuIsOpen={isOpen}
                isSearchable
                autoFocus
                selectTitle={title}
                styles={getCustomStyles()}
                maxMenuHeight={200}
                onMenuScrollToBottom={onScrollList}
                onInputChange={onSearchData}
                placeholder=""
                noOptionsMessage={() => t("noOptionsText")}
            />
        </div>
    );
};

export default InputSelector;
