import React from "react";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "@magmamath/ui";

import Loader from "../index";

const CircleLoader = ({
    color = COLORS.NEUTRAL_1,
    width = 120,
    height = 120,
}) => {
    return (
        <Loader>
            <TailSpin color={color} height={height} width={width} />
        </Loader>
    );
};

export default CircleLoader;
