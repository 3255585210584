import React from "react";

import "./styles.scss";

const LongFormButton = ({ onClick, Icon, title }) => (
    <div className="long-form-button" onClick={onClick}>
        <div className="long-form-button-icon">
            <Icon />
        </div>
        <span className="long-form-button-title">{title}</span>
    </div>
);

export default LongFormButton;
