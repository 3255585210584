import React from 'react';


import skolfederation from '../../../assets/images/skolfederation.png';
import { getSkolfedAuthUrl } from '../../../helpers';
import SSOButton from '../SSOButton';

const SkolfedButton = () => {
    return <SSOButton href={getSkolfedAuthUrl()} image={skolfederation} name='Skolfed' />;
};

export default SkolfedButton;
