import React from "react";
import { COLORS } from "@magmamath/ui";

const CloseIcon = ({ color = COLORS.NEUTRAL_1 }) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3488_44729)">
                <path
                    d="M0.796875 0.796787L24.1839 24.1838"
                    stroke={color}
                    strokeWidth="1.66"
                    strokeLinecap="round"
                />
                <path
                    d="M24.1839 0.796787L0.796875 24.1838"
                    stroke={color}
                    strokeWidth="1.66"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3488_44729">
                    <rect width="25" height="25" fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CloseIcon;
