import React from "react";
import { COLORS } from "@magmamath/ui";

const ArrowFigureIcon = ({ color = COLORS.NEUTRAL_5 }) => {
    return (
        <svg
            width="179"
            height="51"
            viewBox="0 0 179 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.3"
                d="M169.229 16.4799C175.33 31.3376 175.959 44.6041 175.959 44.6041C175.959 44.6041 159.081 38.9044 148.808 44.974M167.402 35.7179C105.28 -9.32413 62.3043 30.9389 81.4157 42.3458C100.527 53.7529 148.666 5.76256 74.9239 2.77076C54.8777 1.95748 16.7544 18.2913 3.86334 44.9318C1.71548 49.3706 5.27663 47.5185 6.45144 43.517"
                stroke={color}
                strokeWidth="5.36354"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowFigureIcon;
