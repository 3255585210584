import React, { useState, useEffect } from 'react';

import { TEACHER, FIRST_PAGE, SECOND_PAGE, REGULAR_AUTH, SKOLFED_AUTH, SIGN_UP } from '../../../constants';
import { signAccordingToAuthType, getSchoolByCode } from '../../../helpers/apiCallHelper';
import { onNextButtonPress } from '../../../helpers/users';
import { userParamsFromURL } from '../../../helpers/users/userParamsHelper';
import createRegisterFormSchema from '../../../helpers/users/teacherRegisterFormSchema';

import TeacherData from './TeacherData';
import TeacherSettings from './TeacherSettings';

import useForm from '../../../hooks/useForm';
import useEnterPress from '../../../hooks/useEnterPress';
import { appLocale } from '../../../features/localisation/helpers';

const RegisterTeacher = ({ params }) => {
    const [page, setPage] = useState(FIRST_PAGE);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [authType, setAuthType] = useState(REGULAR_AUTH);
    const [disableButton, setDisableButton] = useState(false);

    const submitButtonClick = async () => {
        const { firstName, lastName, username, password } = state;

        const body = {
            firstName: firstName.value,
            lastName: lastName.value,
            username: username.value?.trim(),
            email: username.value,
            password: password.value,
            type: TEACHER,
            schoolId: selectedSchool.id
        };

        await signAccordingToAuthType({ authType, body, params, setDisableButton, locale: appLocale });
    };

    const { stateSchema, validationStateSchema } = createRegisterFormSchema();
    const { state, handleOnChange, handleOnSubmit, checkValidity, setStateField } = useForm({ stateSchema, validationSchema: validationStateSchema, callback: submitButtonClick, signType: SIGN_UP });

    useEffect(() => {
        if (!params || !Object.keys(params).length) {
            return;
        }

        userParamsFromURL(params, state, setStateField, checkValidity, () => setPage(SECOND_PAGE));
        setAuthType(SKOLFED_AUTH);

        if (!params.schoolCode) {
            return;
        }

        const loadSchoolByCode = async () => {
            const school = await getSchoolByCode(params.schoolCode);
            if (school) {
                setSelectedSchool({ name: school.name, id: school._id, disabled: true });
            }
        }

        loadSchoolByCode();
    }, [params]);

    useEnterPress(state, authType, params?.email, setStateField, setPage);

    return (
        <>
            {page === FIRST_PAGE ?
                (
                    <TeacherData
                        state={state}
                        handleOnChange={handleOnChange}
                        onNextButtonPress={() => onNextButtonPress({ authType, username: (state.username.value)?.trim(), email: params?.email, setField: setStateField, callback: () => setPage(SECOND_PAGE) })}
                    />
                ) : (
                    <TeacherSettings
                        selectedSchool={selectedSchool}
                        setSelectedSchool={setSelectedSchool}
                        handleOnSubmit={handleOnSubmit}
                        onBackButtonPress={() => setPage(FIRST_PAGE)}
                        disableButton={disableButton}
                    />
                )
            }
        </>
    );
};

export default RegisterTeacher;