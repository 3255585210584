import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../ui/Checkbox/Checkbox'

import './styles.scss';

import { getCountries, getMunicipalities, getRegions, getSchools } from '../../../../helpers/apiCallHelper';
import { getCurrentAgreementsURL } from '../../../../helpers';
import { COUNTRY, MUNICIPALITY, REGION, SCHOOL } from '../../../../constants/selectorConstants';
import InputSelector from '../../../AuthForm/InputSelector';
import CardWrapper from '../../../CardWrapper';
import FormButton from '../../../AuthForm/FormButton';
import PageTitle from '../../../PageTitle';
import { appLocale } from '../../../../features/localisation/helpers';

const MunicipalitySelectorBase = {
    Countries: 'countries',
    Regions: 'regions'
};

const SchoolSelectorBase = {
    Regions: 'regions',
    Municipalities: 'municipalities',
};

const CountryDivisionBasedOn = {
    Municipalities: 1,
    Regions: 2,
    RegionsAndMunicipalities: 3,
};

const TeacherSettings = (props) => {
    const { t } = useTranslation();
    const [countries, setCountries] = useState({ data: null, pages: 0 });
    const [regions, setRegions] = useState(null);
    const [municipalities, setMunicipalities] = useState(null);
    const [schools, setSchools] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);

    const [agreementTermsAccepted, setAgreementTermsAccepted] = useState(false);
    const [openedSelector, setOpenedSelector] = useState(COUNTRY);

    const isRegionsSelectorType = selectedCountry?.dividedBasedOn === CountryDivisionBasedOn.Regions;
    const isAllSelectorsType = selectedCountry?.dividedBasedOn === CountryDivisionBasedOn.RegionsAndMunicipalities;
    const isShowRegionsSelector = isRegionsSelectorType || isAllSelectorsType;

    useEffect(() => {
        const loadCountries = async () => {
            const countriesResponse = await getCountries({});
            setCountries({ data: countriesResponse.countries, pages: countriesResponse.pages });
        };

        loadCountries();
    }, []);

    useEffect(() => {
        setRegions(null);
        setSelectedRegion(null);
        setMunicipalities(null);
        setSelectedMunicipality(null);

        if (!selectedCountry) {
            return;
        }

        const isShowAllSelectors = selectedCountry.dividedBasedOn === CountryDivisionBasedOn.RegionsAndMunicipalities;
        const isShowRegionSelector = selectedCountry.dividedBasedOn === CountryDivisionBasedOn.Regions;

        const loadData = async () => {
            const response = (isShowAllSelectors || isShowRegionSelector) ?
                await getRegions({ id: selectedCountry.id }) :
                await getMunicipalities({ id: selectedCountry.id });

            const setterCallback = (isShowAllSelectors || isShowRegionSelector) ? setRegions : setMunicipalities;
            setterCallback(response);
        }

        loadData();
    }, [selectedCountry]);

    useEffect(() => {
        if (isAllSelectorsType) {
            setMunicipalities(null);
            setSelectedMunicipality(null);
        } else {
            setSchools(null);
            props.setSelectedSchool(null);
        }

        if (!selectedRegion) return;

        if (isAllSelectorsType) {
            const loadMunicipalities = async () => {
                const response = await getMunicipalities({ id: selectedRegion.id, type: MunicipalitySelectorBase.Regions });
                setMunicipalities(response);
            };

            loadMunicipalities();
            return;
        }

        const loadSchools = async () => {
            const schoolsResponse = await getSchools({ id: selectedRegion.id, type: SchoolSelectorBase.Regions });
            setSchools(schoolsResponse);
        };

        loadSchools();
    }, [selectedRegion, isAllSelectorsType]);

    useEffect(() => {
        setSchools(null);
        props.setSelectedSchool(null);
        if (!selectedMunicipality) return;

        const loadSchools = async () => {
            const schoolsResponse = await getSchools({ id: selectedMunicipality.id, type: SchoolSelectorBase.Municipalities });
            setSchools(schoolsResponse);
        };

        loadSchools();
    }, [selectedMunicipality]);

    const pullUpMunicipalities = useCallback(async (body) => {
        body.type = isAllSelectorsType ? MunicipalitySelectorBase.Regions : MunicipalitySelectorBase.Countries;
        return getMunicipalities(body);
    }, [isAllSelectorsType]);

    const pullUpSchools = useCallback(async (body) => {
        body.type = isRegionsSelectorType ? SchoolSelectorBase.Regions : SchoolSelectorBase.Municipalities;
        return await getSchools(body);
    }, [isRegionsSelectorType]);

    const onItemSelect = useCallback(({ type, icon, name, id, dividedBasedOn }) => {
        const body = { name, id, dividedBasedOn };
        icon && (body.icon = icon);

        if (type === COUNTRY) {
            const isShowAllSelectors = dividedBasedOn === CountryDivisionBasedOn.RegionsAndMunicipalities;
            const isShowRegionSelector = dividedBasedOn === CountryDivisionBasedOn.Regions
            setSelectedCountry(body);
            setOpenedSelector((isShowAllSelectors || isShowRegionSelector) ? REGION : MUNICIPALITY);
            return;
        }

        if (type === REGION) {
            setSelectedRegion(body);
            if (isAllSelectorsType) {
                setSelectedMunicipality(null);
                setOpenedSelector(MUNICIPALITY);
                return;
            }

            setOpenedSelector(SCHOOL);
            return;
        }

        if (type === MUNICIPALITY) {
            setSelectedMunicipality(body);
            setOpenedSelector(SCHOOL);
            return;
        }

        props.setSelectedSchool(body);
    }, [isAllSelectorsType]);

    const acceptTermsClick = useCallback(() => {
        setAgreementTermsAccepted(!agreementTermsAccepted);
    }, [agreementTermsAccepted]);

    return (
        <CardWrapper onBackButtonPress={props.onBackButtonPress}>
            <div className="register-teacher-settings">
                <PageTitle title={t(props.selectedSchool?.disabled ? 'reviewAndAcceptTermsText' : 'enterSchoolText')} />
                {
                    props.selectedSchool?.disabled ? null : (
                        <div className="school-selector-container">
                            <InputSelector
                                options={countries.data}
                                title={t('countryText')}
                                selectedItem={selectedCountry}
                                selectItem={onItemSelect}
                                type={COUNTRY}
                                pullUpData={getCountries}
                                setData={setCountries}
                                limit={countries.pages}
                                isSelectorOpen={openedSelector === COUNTRY}
                            />
                            <InputSelector
                                options={isShowRegionsSelector ? regions : municipalities}
                                title={t(isShowRegionsSelector ? 'regionText' : 'municipalityText')}
                                type={isShowRegionsSelector ? REGION : MUNICIPALITY}
                                disable={isShowRegionsSelector ? !regions : !municipalities}
                                selectedItem={isShowRegionsSelector ? selectedRegion : selectedMunicipality}
                                selectItem={onItemSelect}
                                pullUpData={isShowRegionsSelector ? getRegions : pullUpMunicipalities}
                                setData={isShowRegionsSelector ? setRegions : setMunicipalities}
                                limit={isShowRegionsSelector ? regions?.length : municipalities?.length}
                                id={selectedCountry?.id}
                                isSelectorOpen={openedSelector === (isShowRegionsSelector ? REGION : MUNICIPALITY)}
                            />
                            {isAllSelectorsType && (
                                <InputSelector
                                    options={municipalities}
                                    title={t('municipalityText')}
                                    type={MUNICIPALITY}
                                    disable={!municipalities}
                                    selectedItem={selectedMunicipality}
                                    selectItem={onItemSelect}
                                    pullUpData={pullUpMunicipalities}
                                    setData={setMunicipalities}
                                    limit={municipalities?.length}
                                    id={selectedRegion?.id}
                                    isSelectorOpen={openedSelector === MUNICIPALITY}
                                />
                            )}
                            <InputSelector
                                options={schools}
                                title={t('schoolText')}
                                type={SCHOOL}
                                disable={(!schools && !props.selectedSchool) || props.selectedSchool?.disabled}
                                selectedItem={props.selectedSchool}
                                selectItem={onItemSelect}
                                pullUpData={pullUpSchools}
                                setData={setSchools}
                                limit={schools?.length}
                                id={isRegionsSelectorType ? selectedRegion?.id : selectedMunicipality?.id}
                                isSelectorOpen={openedSelector === SCHOOL}
                            />
                        </div>
                    )
                }
                <div className="accept-terms-container">
                    <Checkbox
                      isChecked={agreementTermsAccepted}
                      onChange={acceptTermsClick}
                      label={<>
                        <span>{t('acceptAgreementTermsText')}</span>
                        <a href={getCurrentAgreementsURL(appLocale)} target="_blank" rel="noreferrer">
                            {t('acceptAgreementTermsLinkText')}
                        </a>
                      </>}
                    />
                </div>
                <div className="sign-up-teacher-button-container">
                    <div className="complete-register-button">
                        <FormButton
                            title={t('signUpText')}
                            type="signin"
                            disable={!agreementTermsAccepted || !props.selectedSchool || props.disableButton}
                            onClick={props.handleOnSubmit}
                        />
                    </div>
                </div>
            </div>
        </CardWrapper>
    );
};

export default TeacherSettings;