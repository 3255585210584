import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

import ArrowBackIcon from "../../ui/icons/ArrowBackIcon";

const BackButton = (props) => {
    const { t } = useTranslation();

    return (
        <div className="back-button" onClick={props.onClick}>
            <div className="back-button-icon">
                <ArrowBackIcon />
            </div>
            <span className="back-button-text">{t("backText")}</span>
        </div>
    );
};

export default BackButton;
