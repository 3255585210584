import logger from '../logger';
import Bowser from 'bowser';

import { logglyMessage } from '../constants/logglyMessageTypes';

export const getBrowserInfo = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowser();
};

export const getCurrentUrl = () => {
    return window.location?.href;
};

export const writeLogg = (error) => {
    const loggBody = {
        level: logglyMessage.error,
        message: error.message,
        browserName: getBrowserInfo().name,
        browserVersion: getBrowserInfo().version,
        currentURL: getCurrentUrl(),
    };

    const errorResponse = error.response;
    const requestMethod = errorResponse?.config?.method;
    const requestUrl = errorResponse?.request?.responseURL;
    requestMethod && (loggBody.requestMethod = requestMethod);
    requestUrl && (loggBody.requestUrl = requestUrl);

    logger.push(loggBody);
};
