import React from 'react';

import './styles.scss';

const SSOButton = ({ href, image, name }) => {
    return (
        <a className="sso-button" href={href}>
            <div className="sso-button-icon">
                <img src={image} alt={`${name}-sign`} className="sso-icon" />
            </div>
            <span className="sso-button-title">{name}</span>
        </a>
    );
};

export default SSOButton;