import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

import { disableCreateAccountNextButton } from '../../../../helpers/logicHelper';
import RegisterUserForm from '../../RegisterUserForm';
import FormButton from '../../../AuthForm/FormButton';
import CardWrapper from '../../../CardWrapper';
import PageTitle from '../../../PageTitle';
import history from '../../../../history';

const StudentData = (props) => {
    const { t } = useTranslation();

    return (
        <CardWrapper onBackButtonPress={() => history.goBack()}>
            <div className="register-user-data-container">
                <PageTitle title={t('enterYourDetailsText')} />
                <RegisterUserForm
                    data={props.state}
                    onChange={props.onChange}
                />
                <div className="next-button">
                    <FormButton
                        onClick={props.onNextButtonPress}
                        title={t('nextText')}
                        type="signin"
                        disable={disableCreateAccountNextButton(props.state)}
                    />
                </div>
            </div>
        </CardWrapper>
    );
};

export default StudentData;