import React from "react";
import { COLORS } from "@magmamath/ui";

const StudentIcon = ({
    color = COLORS.NEUTRAL_10,
    secondaryColor = COLORS.NEUTRAL_1,
}) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_123_226)">
                <path
                    d="M12.5026 9.49744C14.8966 9.49744 16.8373 7.55675 16.8373 5.16278C16.8373 2.76882 14.8966 0.828125 12.5026 0.828125C10.1087 0.828125 8.16797 2.76882 8.16797 5.16278C8.16797 7.55675 10.1087 9.49744 12.5026 9.49744Z"
                    stroke={color}
                    strokeWidth="1.6565"
                />
                <path
                    d="M12.5052 13.3226C18.3716 13.3226 23.1274 18.0782 23.1274 23.9447H24.7838C24.7838 17.1634 19.2865 11.666 12.5052 11.666V13.3226ZM1.88308 23.9447C1.88308 18.0782 6.63876 13.3226 12.5052 13.3226V11.666C5.7239 11.666 0.226562 17.1634 0.226562 23.9447H1.88308ZM1.05484 24.7729H23.9555V23.1164H1.05484V24.7729ZM0.226562 23.9447C0.226562 24.4021 0.597395 24.7729 1.05484 24.7729V23.1164C1.51226 23.1164 1.88308 23.4872 1.88308 23.9447H0.226562ZM23.1274 23.9447C23.1274 23.4638 23.5219 23.1164 23.9555 23.1164V24.7729C24.3892 24.7729 24.7838 24.4255 24.7838 23.9447H23.1274Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_123_226">
                    <rect width="25" height="25" fill={secondaryColor} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default StudentIcon;
