import React from "react";
import { COLORS } from "@magmamath/ui";

const TeacherIcon = ({ color = COLORS.NEUTRAL_10 }) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.89844 4.66H22.8984C23.0861 4.66 23.2384 4.81222 23.2384 5V14.3C23.2384 14.4878 23.0861 14.64 22.8984 14.64H12.5828C12.6961 15.1749 12.7807 15.7297 12.8338 16.3H22.8984C24.0029 16.3 24.8984 15.4046 24.8984 14.3V5C24.8984 3.89543 24.0029 3 22.8984 3H6.89844C5.79387 3 4.89844 3.89543 4.89844 5V8.01737C5.38034 7.84253 5.88297 7.75 6.39968 7.75C6.45275 7.75 6.50567 7.75098 6.55844 7.75292V5C6.55844 4.81222 6.71066 4.66 6.89844 4.66Z"
                fill={color}
            />
            <rect
                x="15.8984"
                y="13.4492"
                width="4.99998"
                height="1.9"
                rx="0.5"
                fill={color}
            />
            <path
                d="M6.89841 16.3008C2.8389 16.3008 1.35434 19.0188 0.992918 21.0068C0.894129 21.5501 1.34615 22.0008 1.89844 22.0008H11.8984C12.4507 22.0008 12.9027 21.5501 12.8039 21.0068C12.4425 19.0188 10.9579 16.3008 6.89841 16.3008Z"
                stroke={color}
                strokeWidth="1.66"
            />
            <path
                d="M9.89841 11.5512C9.89841 13.1252 8.55527 14.4012 6.89842 14.4012C5.24158 14.4012 3.89844 13.1252 3.89844 11.5512C3.89844 9.97716 5.24158 8.70117 6.89842 8.70117C8.55527 8.70117 9.89841 9.97716 9.89841 11.5512ZM5.17276 11.5512C5.17276 12.4566 5.94536 13.1906 6.89842 13.1906C7.85148 13.1906 8.62409 12.4566 8.62409 11.5512C8.62409 10.6458 7.85148 9.91178 6.89842 9.91178C5.94536 9.91178 5.17276 10.6458 5.17276 11.5512Z"
                fill={color}
            />
        </svg>
    );
};

export default TeacherIcon;
