import React from 'react';
import { components } from 'react-select';

import './styles.scss';

const SingleValue = ({ children, ...props }) => {
    const renderValue = () => {
        if (!props.selectProps?.value) {
            return {children};
        }

        const selectedItem = props.selectProps.value;
        return (
            <div className="selected-option">
                {selectedItem?.icon && <img src={selectedItem.icon} alt="item icon" />}
                <span>{selectedItem.value}</span>
            </div>
        )
    };

    return (
        <components.SingleValue {...props}>{renderValue()}</components.SingleValue>
    );
};

export default SingleValue;