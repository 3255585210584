import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './styles.scss';
import { SIGN_IN, AUTH_ERROR_CODE } from '../../constants';
import { CLEVER_AUTH_ERROR_MESSAGE } from '../../constants/customErrorMessages';
import { signIn, autoSignInUser, autoExerciseRedirect, signWithQRCode } from '../../helpers/apiCallHelper';
import { showErrorToast } from '../../constants/toasts';
import useForm from '../../hooks/useForm';
import createSignFormSchema from '../../helpers/users/userSignFormSchema';

import AuthForm from '../AuthForm';
import LoginForm from './LoginForm';
import SSOButtons from '../SSOButtons';
import QRCodeButton from '../SSOButtons/QRCodeButton';
import QRCode from '../QRCode';

import history from '../../history';
import { appLocale } from '../../features/localisation/helpers';

const Login = () => {
    const { t } = useTranslation();

    const [disableButton, setDisableButton] = useState(false);
    const [isQRCodeScannerActive, setIsQRCodeScannerActive] = useState(false);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (Number(params?.['auth-error']) === AUTH_ERROR_CODE) {
            showErrorToast({ message: CLEVER_AUTH_ERROR_MESSAGE, toastId: new Date().getTime() });
            history.push('/signin');
            return;
        }

        const userId = params?.userId;
        const userType = params?.type;
        const autologinToken = params?.token;

        if (userId && userType && autologinToken) {
            return autoSignInUser({ userId, userType, autologinToken, locale : appLocale });
        }

        return autoExerciseRedirect({ redirectUrl: params?.redirectURL, locale: appLocale })
    }, []);

    const { stateSchema, validationStateSchema } = createSignFormSchema();

    const onSubmit = async (state) => {
        setDisableButton(true);
        await signIn({ username: state.username.value, password: state.password.value }, appLocale);
        setDisableButton(false);
    };

    const { state, handleOnChange, handleOnSubmit } = useForm({ stateSchema, validationSchema: validationStateSchema, callback: onSubmit, signType: SIGN_IN });

    const onSignButtonPress = useCallback(async () => {
        if (!state.username?.value || !state.password?.value) {
            showErrorToast({ message: t('fillUsernameAndPasswordText'), toastId: new Date().getTime() });
            return;
        }

        await onSubmit(state)
    }, [state, t]);

    const onEnterPress = useCallback((e) => {
        if (e.key !== 'Enter') {
            return;
        }

        handleOnSubmit(e);
    }, [handleOnSubmit]);

    useEffect(() => {
        document.addEventListener('keypress', onEnterPress);

        return () => {
            document.removeEventListener('keypress', onEnterPress);
        };
    }, [onEnterPress]);

    const onSuccessScan = (code) => {
        signWithQRCode(code, appLocale)
    };

    return (
        <>
            {isQRCodeScannerActive ? (
                <QRCode closeScanner={() => setIsQRCodeScannerActive(false)} onScanComplete={onSuccessScan} />
            ) : (
                <div className="signin-form-container">
                    <AuthForm type={SIGN_IN}>
                        <div className="signin-form-data-input-container">
                            <div className='login-buttons-container'>
                                <SSOButtons />
                                <QRCodeButton onClick={() => setIsQRCodeScannerActive(true)} />
                            </div>
                            <LoginForm
                                username={state.username}
                                password={state.password}
                                handleOnChange={handleOnChange}
                                handleOnSubmit={onSignButtonPress}
                                disableButton={disableButton}
                            />
                        </div>
                        <div className="bottom-text-container">
                            <Link to="/forgot-password" className="forgot-password">{t('forgotPasswordText')}</Link>
                            <div className="go-to-register-container">
                                <div className="go-to-register-page">
                                    <span className="go-to-register-text">{t('noAccountText')}</span>
                                    <Link to="/signup" className="link-to-register-page go-to-register-text">{t('signUpHereText')}</Link>
                                </div>
                            </div>
                        </div>
                    </AuthForm>
                </div>
            )}
        </>
    );
};

export default Login;
