import { useCallback, useRef, useState } from 'react';
import {authMe, checkIsEmailExists} from '../helpers/apiCallHelper';
import { USERNAME_FIELD } from '../constants/fieldNames';
import { EMAIL_VALIDATOR, SIGN_IN } from '../constants';

const useForm = ({ stateSchema, validationSchema = {}, callback, signType }) => {
    const inputRef = useRef(null);
    const timeoutRef = useRef(null);

    const [state, setState] = useState(stateSchema);

    const validateState = useCallback(() => {
        return Object.keys(validationSchema).some(key => {
            const isInputFieldRequired = validationSchema[key].required;
            const stateValue = state[key].value;
            const stateError = state[key].error;

            return (isInputFieldRequired && !stateValue) || stateError;
        });
    }, [state, validationSchema]);

    const checkValidity = useCallback((name, value) => {
            let error = '';
            if (validationSchema[name].required) {
                if (!value) {
                    error = validationSchema[name].error;
                }
            }

            if (
                validationSchema[name].validator !== null &&
                typeof validationSchema[name].validator === 'object'
            ) {
                if (!value) {
                    return { value, error, touched: true };
                }

                if (validationSchema[name].validator.lengthRegEx &&
                    !validationSchema[name].validator.lengthRegEx.test(value)
                ) {
                    error = validationSchema[name].validator.lengthError;
                } else  {
                    if (validationSchema[name].validator.structureRegEx &&
                        !validationSchema[name].validator.structureRegEx.test(value)
                    ) {
                        error = validationSchema[name].validator.structureError;
                    }
                }
            }

            return { value, error, touched: true };
        },
        [validationSchema]);

    const handleOnChange = useCallback(
        event => {
            const name = event.target.name;
            const inputValue = event.target.value;

            const { value, error, touched } = checkValidity(name, inputValue);
            setState(prevState => ({
                ...prevState,
                [name]: { value, error, touched },
            }));

            if (signType === SIGN_IN) return;

            if (name === USERNAME_FIELD && !error) {
                clearTimeout(timeoutRef.current);
                if (inputRef.current === value) return;
                inputRef.current = value;
                timeoutRef.current = setTimeout(async () => {
                    const responseUsernameMessage = await authMe(value);
                    let responseEmailMessage;
                    if (EMAIL_VALIDATOR.test(value)) {
                        responseEmailMessage = await checkIsEmailExists(value);
                    }
                    if (responseUsernameMessage || responseEmailMessage) {
                        setState(prevState => ({
                            ...prevState,
                            [USERNAME_FIELD]: { value, error: responseUsernameMessage || responseEmailMessage, touched },
                        }));
                    }
                }, 500);
            }
        },
        [checkValidity]
    );


    const handleOnSubmit = useCallback(
        event => {
            event.preventDefault();

            if (!validateState()) {
                callback(state);
            } else {
                const newState = {};
                Object.keys(validationSchema).forEach(key => {
                    const inputValue = state[key].value;

                    const { value, error, touched } = checkValidity(key, inputValue);

                    newState[key] = { value, error, touched };
                });

                setState(newState);
            }
        },
        [callback, checkValidity, state, validateState, validationSchema]
    );

    const setStateField = useCallback((name, field, value) => {
        const newState = { ...state};
        if (!newState[name]) {
            return;
        }

        newState[name][field] = value;
        setState(newState);
    }, [state]);

    return { state, handleOnChange, handleOnSubmit, checkValidity, setStateField, setState };
};

export default useForm;