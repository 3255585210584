import React from "react";
import { COLORS } from "@magmamath/ui";

const ArrowBackIcon = ({ color = COLORS.NEUTRAL_10 }) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 1L7.86005 5.65754C8.0549 5.84427 8.05489 6.15573 7.86005 6.34246L3 11"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default ArrowBackIcon;
