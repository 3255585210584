import styles from './Checkbox.module.scss'
import CheckIcon from '../icons/CheckIcon'
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';

const Checkbox = ({  isChecked, onChange, label }) => {
  const [checkboxId] = useState(uuidv4())

  return (
    <label htmlFor={checkboxId} className={styles.Label}>
    <span className={styles.CheckboxInput}>
      <input
        id={checkboxId}
        type="checkbox"
        name="checkbox"
        checked={isChecked}
        onChange={onChange}
        className={styles.Input}
      />
      <span className={styles.CheckWrapper}>
        {isChecked && <CheckIcon size={12}/>}
      </span>
    </span>
    <span className={styles.CheckboxText}>{label}</span>
  </label>
  )
}

export default Checkbox