import { LOCALE_AUTH_ENV_CONFIG } from "../configs";
import { APP_LOCALE, USER_REFERED_LOCALE_LOCAL_STORAGE_KEY, USER_PREFERED_LOCALE_SEARCH_PARAM, LOCALE_PATH_REGEXP } from "../contstants";
import { isAppLocale } from "./checkLocalePath";

export const appLocale = proceedLocalisation() || APP_LOCALE.US;

export function proceedLocalisation() {
    const currentLocale = getCurrentLocaleByURL();

    const currentUrl = new URL(window.location.href);
    const preferenceParam = currentUrl.searchParams.get(USER_PREFERED_LOCALE_SEARCH_PARAM);

    if (preferenceParam === 'true') {
        localStorage.setItem(USER_REFERED_LOCALE_LOCAL_STORAGE_KEY, currentLocale);
        
        clearRedirectSearchParam(USER_PREFERED_LOCALE_SEARCH_PARAM);

        return currentLocale;
    }

    const userLSLocalePreference = localStorage.getItem(USER_REFERED_LOCALE_LOCAL_STORAGE_KEY);

    if (userLSLocalePreference && Object.values(APP_LOCALE).find(locale => locale === userLSLocalePreference)) {
        if (userLSLocalePreference === currentLocale) {
            clearRedirectSearchParam(USER_PREFERED_LOCALE_SEARCH_PARAM);
            return userLSLocalePreference;
        }
        window.location.href = `${LOCALE_AUTH_ENV_CONFIG[userLSLocalePreference]}${window.location.pathname.replace(LOCALE_PATH_REGEXP, '')}${window.location.search}`;
        return null;
    }

    const browserLocale = getBrowserLocale();

    if (browserLocale && browserLocale !== currentLocale && LOCALE_AUTH_ENV_CONFIG[browserLocale]) {
        window.location.href = `${LOCALE_AUTH_ENV_CONFIG[browserLocale]}${window.location.pathname.replace(LOCALE_PATH_REGEXP, '')}${window.location.search}`;
        return null;
    }

    return currentLocale;
};

function clearRedirectSearchParam(searchParamKey) {
    const currentUrl = new URL(window.location.href);
    const preferenceParam = currentUrl.searchParams.get(searchParamKey);

    if (preferenceParam) {
        currentUrl.searchParams.delete(searchParamKey);
        window.history.pushState({}, '', currentUrl);
    }
}

function getBrowserLocale() {
    const userLanguages = navigator.languages;

    for (let locale of userLanguages) {
        if (locale.includes('sv')) {
            return APP_LOCALE.swedish;
        } 
        if (locale === APP_LOCALE["english-british"] || locale === 'en-IE') {
            return APP_LOCALE["english-british"];
        } 
        if (locale.includes('en')) {
           return APP_LOCALE.US;
        }
    }
    
    return null;
}

function getCurrentLocaleByURL() {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    const tld = domainParts[domainParts.length - 1];

    if (tld === 'se') {
        return APP_LOCALE.swedish;
    }

    const localePath = window.location.pathname.split('/')[1];

    if (localePath && isAppLocale(localePath)) {
        return APP_LOCALE["english-british"];
    }

    return APP_LOCALE.US;
}