import { toast, Zoom } from 'react-toastify';

import './styles.scss';

import ReloadPage from '../../components/ReloadPage';

export const showErrorToast = ({ message, toastId }) => {
    toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        transition: Zoom,
        hideProgressBar: true,
        pauseOnHover: false,
        className: 'toast-container',
        toastId: toastId
    });
};

export const showReloadPageToast = ({ toastId }) => {
    toast(<ReloadPage />, {
        position: toast.POSITION.TOP_CENTER,
        transition: Zoom,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: false,
        autoClose: false,
        className: 'toast-container reload-page',
        toastId: toastId
    });
};

export const showInfoPopup = ({ message, toastId }) => {
    toast(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        transition: Zoom,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: false,
        autoClose: true,
        className: 'toast-container info-toast',
        toastId: toastId
    });
};