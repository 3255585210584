import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();
    const stateSchema = {
        username: {value: '', error: '', touched: false},
        password: {value: '', error: '', touched: false}
    };

    const validationStateSchema = {
        username: {
            required: true,
            error: t('requiredFieldText')
        },
        password: {
            required: true,
            error: t('requiredFieldText'),
            validator: {
                lengthRegEx: /.{6,}/,
                lengthError: t('validatePasswordLengthText')
            }
        }
    };

    return { stateSchema, validationStateSchema };
};