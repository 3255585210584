import React from 'react';
import { Redirect } from 'react-router-dom';

import { TEACHER_TEXT, STUDENT_TEXT } from '../../../constants';
import RegisterStudent from '../RegisterStudent';
import RegisterTeacher from '../RegisterTeacher';
import { getParamsFromURL } from '../../../helpers';

const RegistrationFormSelector = (props) => {
    if (!props.match.params.type) {
        return;
    }

    return props.match.params.type === STUDENT_TEXT ? (
        <RegisterStudent
            params={getParamsFromURL()}
        />
    ) : props.match.params.type === TEACHER_TEXT ? (
        <RegisterTeacher
            params={getParamsFromURL()}
        />
    ) : <Redirect to="signup/user-select"/>;
};

export default RegistrationFormSelector;