import React from 'react';

import { GOOGLE } from '../../../constants';
import { getSSOAuthUrl } from '../../../helpers';
import google from '../../../assets/images/google.png';
import SSOButton from '../SSOButton';

const GoogleButton = ({locale}) => {
    return <SSOButton href={getSSOAuthUrl(GOOGLE, locale)} image={google} name='Google' />;
};

export default GoogleButton;