import React from "react";
import { COLORS } from "@magmamath/ui";

const KeyIcon = ({
    color = COLORS.NEUTRAL_10,
    secondaryColor = COLORS.NEUTRAL_1,
}) => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1528_1053)">
            <path
                d="M10.3207 11.168L1.21149 20.2771C1.18863 20.3 1.17578 20.331 1.17578 20.3634V23.8489C1.17578 23.9162 1.23037 23.9708 1.29771 23.9708H5.32146C5.3888 23.9708 5.44339 23.9162 5.44339 23.8489V21.6541C5.44339 21.5868 5.49798 21.5322 5.56532 21.5322H8.34295C8.4199 21.5322 8.47761 21.4618 8.46252 21.3863L7.91582 18.6528C7.89932 18.5703 7.96933 18.4963 8.0526 18.5082L11.3363 18.9766C11.4176 18.9882 11.4869 18.9178 11.4739 18.8367L10.9595 15.6179C10.9457 15.5315 11.0246 15.4591 11.1094 15.4804L13.302 16.0285C13.3435 16.0389 13.3875 16.0267 13.4178 15.9964L14.5883 14.8259"
                stroke={color}
                strokeWidth="2.02407"
            />
            <path
                d="M18.4607 14.7045C17.0927 15.0486 15.4989 15.3234 14.1897 15.1412C14.1899 15.1409 14.1901 15.1407 14.1903 15.1404L14.7926 14.3374C14.8389 14.2756 14.8846 14.2721 14.8937 14.2727C15.1569 14.29 15.4081 14.3167 15.6711 14.345L15.7036 14.3486C15.9509 14.3753 16.2148 14.4037 16.4803 14.4212C17.0484 14.4586 17.6612 14.4489 18.3527 14.275C19.8941 13.8873 21.2389 12.9458 22.1307 11.6301C23.0224 10.3144 23.3987 8.71649 23.1878 7.14111C22.9768 5.56574 22.1935 4.12311 20.987 3.08832C19.7806 2.05353 18.2355 1.49897 16.6464 1.53041C15.0573 1.56184 13.5353 2.17706 12.3707 3.25875C11.2062 4.34045 10.4804 5.81293 10.332 7.39541C10.3135 7.59274 10.2843 7.79682 10.2502 8.0261C10.2469 8.04788 10.2436 8.06995 10.2403 8.09227C10.2098 8.29653 10.1761 8.52234 10.1509 8.74843C10.0949 9.25066 10.0687 9.84378 10.2238 10.4585C10.2931 10.7331 10.3104 10.9878 10.2809 11.1713C10.2541 11.3373 10.2061 11.3671 10.1983 11.372C10.198 11.3722 10.1978 11.3723 10.1977 11.3724C10.1335 11.4144 10.0698 11.4556 10.007 11.4955C9.97359 11.3044 9.94284 11.0796 9.91677 10.8239C9.8008 9.6869 9.80628 8.25748 9.89104 7.35404C10.0497 5.66325 10.825 4.08998 12.0693 2.93425C13.3136 1.77852 14.9397 1.12119 16.6376 1.08761C18.3355 1.05402 19.9864 1.64653 21.2754 2.75215C22.5644 3.85777 23.4014 5.39914 23.6267 7.08234C23.8521 8.76554 23.4501 10.4728 22.4973 11.8786C21.5445 13.2843 20.1077 14.2902 18.4607 14.7045ZM10.1771 12.1331C10.1771 12.1331 10.1764 12.132 10.1751 12.1296C10.1765 12.1318 10.1772 12.133 10.1771 12.1331Z"
                stroke={color}
                strokeWidth="2.02407"
            />
            <circle
                cx="17.5126"
                cy="7.0282"
                r="1.21932"
                fill={color}
                stroke={color}
                strokeWidth="1.21932"
            />
        </g>
        <defs>
            <clipPath id="clip0_1528_1053">
                <rect width="25" height="25" fill={secondaryColor} />
            </clipPath>
        </defs>
    </svg>
);

export default KeyIcon;
