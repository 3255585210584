import React from 'react';
import { components } from 'react-select';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            {props?.selectProps.menuIsOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </components.DropdownIndicator>
    );
};

export default DropdownIndicator;