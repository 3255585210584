import React from 'react';

import './styles.scss';

const ClassCodeItem = (props) => {
    return (
        <input
            className={`box-item ${props.error ? 'error' : ''}`}
            value={props.value}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            inputMode="numeric"
            name={props.name}
        />
    );
};

export default ClassCodeItem;