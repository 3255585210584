import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

import ClassCode from '../../ClassCode';
import FormButton from '../../../AuthForm/FormButton';
import BackButton from '../../../BackButton';

import history from '../../../../history';
const StudentSettings = (props) => {
    const { t } = useTranslation();

    return (
        <div className='class-code-page-wrapper'>
            <div className='class-code-page'>
                <BackButton onClick={() => history.goBack()} />
                <span className="enter-class-code-message">{t('classCodeText')}</span>
                <ClassCode
                    classCode={props.classCode}
                    onChange={props.onChange}
                />
                <div className="sign-up-student-button-container">
                    <div className="complete-register-button">
                        <FormButton
                            title={t('signUpText')}
                            type="signin"
                            onClick={props.onSubmit}
                            disable={props.disableButton}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentSettings;