const USFlagIcon = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_162)">
            <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#F0F0F0"/>
            <path d="M30.6088 32.0002H64C64 29.112 63.615 26.314 62.8976 23.6523H30.6088V32.0002Z" fill="#D80027"/>
            <path d="M30.6088 15.3044H59.3033C57.3444 12.1079 54.8398 9.28254 51.9195 6.95654H30.6088V15.3044Z" fill="#D80027"/>
            <path d="M32.0001 64C39.5312 64 46.4533 61.397 51.9196 57.0435H12.0806C17.5468 61.397 24.4689 64 32.0001 64Z" fill="#D80027"/>
            <path d="M4.69679 48.6955H59.3033C60.8759 46.1294 62.0955 43.3244 62.8977 40.3477H1.10242C1.90454 43.3244 3.12417 46.1294 4.69679 48.6955Z" fill="#D80027"/>
            <path d="M14.823 4.99725H17.7391L15.0266 6.96788L16.0627 10.1565L13.3504 8.18587L10.638 10.1565L11.533 7.40188C9.14475 9.39125 7.0515 11.722 5.3265 14.319H6.26088L4.53425 15.5734C4.26525 16.0221 4.00725 16.478 3.76 16.9406L4.5845 19.4783L3.04625 18.3606C2.66388 19.1707 2.31413 19.9991 1.99975 20.8447L2.90812 23.6408H6.26088L3.54837 25.6114L4.5845 28.8L1.87213 26.8294L0.247375 28.0099C0.08475 29.3171 0 30.6486 0 32H32C32 14.327 32 12.2435 32 0C25.6785 0 19.7856 1.83375 14.823 4.99725ZM16.0627 28.8L13.3504 26.8294L10.638 28.8L11.6741 25.6114L8.96163 23.6408H12.3144L13.3504 20.4521L14.3864 23.6408H17.7391L15.0266 25.6114L16.0627 28.8ZM15.0266 16.2896L16.0627 19.4783L13.3504 17.5076L10.638 19.4783L11.6741 16.2896L8.96163 14.319H12.3144L13.3504 11.1304L14.3864 14.319H17.7391L15.0266 16.2896ZM27.541 28.8L24.8286 26.8294L22.1162 28.8L23.1524 25.6114L20.4399 23.6408H23.7926L24.8286 20.4521L25.8646 23.6408H29.2174L26.5049 25.6114L27.541 28.8ZM26.5049 16.2896L27.541 19.4783L24.8286 17.5076L22.1162 19.4783L23.1524 16.2896L20.4399 14.319H23.7926L24.8286 11.1304L25.8646 14.319H29.2174L26.5049 16.2896ZM26.5049 6.96788L27.541 10.1565L24.8286 8.18587L22.1162 10.1565L23.1524 6.96788L20.4399 4.99725H23.7926L24.8286 1.80862L25.8646 4.99725H29.2174L26.5049 6.96788Z" fill="#0052B4"/>
        </g>
        <defs>
            <clipPath id="clip0_2301_162">
            <rect width="64" height="64" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default USFlagIcon;
