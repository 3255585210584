import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './styles.scss';

import '../../history'
import Login from '../../components/Login';
import RegisterPage from '../RegisterPage';
import ForgotPassword from '../../components/ForgotPassword';
import ScheduleOnboarding from '../../components/ScheduleOnboarding';

const Sign = () => {
    return (
        <div className="sign-container">
            <Switch>
                <Route exact path='/' render={() => <Redirect to="/signin" />}/>
                <Route path='/signup' component={RegisterPage} />
                <Route exact path='/signin' component={Login}/>
                <Route exact path='/forgot-password' component={ForgotPassword} />
                <Route exact path='/schedule-onboarding' component={ScheduleOnboarding} />
            </Switch>
        </div>
    );
};

export default Sign;
