export const APP_LOCALE = {
    swedish: 'sv-SE',
    US: 'en-SE',
    'english-british': 'en-GB',
};

export const LOCALE_PATH_REGEXP = /\/uk\b/;

export const USER_PREFERED_LOCALE_SEARCH_PARAM = 'userPreference';
export const USER_REFERED_LOCALE_LOCAL_STORAGE_KEY = 'userPreference';
