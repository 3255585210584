import React from "react";

import "./styles.scss";
import LogoIcon from "../../ui/icons/LogoIcon";
import SphereIcon from "../../ui/icons/SphereIcon";
import TriangleIcon from "../../ui/icons/TriangleIcon";
import SinusoidIcon from "../../ui/icons/SinusoidIcon";
import CubeIcon from "../../ui/icons/CubeIcon";

const Logo = () => {
    return (
        <>
            <div className="logo">
                <LogoIcon />
            </div>
            <div className="triangle">
                <TriangleIcon />
            </div>
            <div className="sinusoid">
                <SinusoidIcon />
            </div>
            <div className="cube">
                <CubeIcon />
            </div>
            <div className="sphere">
                <SphereIcon />
            </div>
        </>
    );
};

export default Logo;
