import { useTranslation } from 'react-i18next';

import { EMAIL_VALIDATOR } from '../../constants';

export default () => {
    const { t } = useTranslation();

    const stateSchema = {
        firstName: { value: '', error: '', touched: false },
        lastName: { value: '', error: '', touched: false },
        username: { value: '', error: '', touched: false },
        password: { value: '', error: '', touched: false },
    };

    const validationStateSchema = {
        firstName: {
            required: true,
            error: t('requiredFieldText')
        },
        lastName: {
            required: true,
            error: t('requiredFieldText')
        },
        username: {
            required: true,
            error: t('requiredFieldText'),
            validator: {
                lengthRegEx: /.{4,}/,
                structureRegEx: EMAIL_VALIDATOR,
                lengthError: t('validateTeacherEmailText'),
                structureError: t('validateTeacherEmailText')
            }
        },
        password: {
            required: true,
            error: t('requiredFieldText'),
            validator: {
                lengthRegEx: /.{6,}/,
                lengthError: t('validatePasswordLengthText')
            }
        }
    };

    return { stateSchema, validationStateSchema };
};