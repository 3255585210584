import React from "react";
import { COLORS } from "@magmamath/ui";

const TriangleIcon = ({ color = COLORS.NEUTRAL_3 }) => {
    return (
        <svg
            width="200"
            height="114"
            viewBox="0 0 132 114"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.1454 2.90211C19.9087 3.25708 20.149 6.59764 20.149 10.1596C20.149 13.827 20.0778 16.7763 19.9327 17.1002C19.7877 17.4241 19.9924 17.2565 20.1487 17.1969C20.305 17.1373 18.5742 17.3367 24.1239 17.1002C29.6736 16.8637 30.9549 8.87853 20.7078 11.0131C30.1005 7.59807 23.27 1.19498 20.7075 3.32938"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M127.009 95.1119C126.581 91.2704 120.178 90.844 118.897 101.516C118.384 105.786 121.885 109.628 126.582 106.64C126.881 106.449 127.009 106.213 127.009 105.786"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M30.1783 20.0505C29.7955 20.5813 29.7799 27.6279 29.8522 36.542C29.8968 42.0291 30.1322 48.2239 30.1783 54.042C30.2335 61.011 30.0856 67.4396 29.9378 71.4647C29.4522 84.6897 30.7146 94.2485 30.1486 95.3973C29.5826 96.5462 30.3429 95.969 30.9145 95.7747C31.3532 95.6256 29.68 95.8609 44.5098 95.9785C49.0028 96.0141 58.5925 95.02 63.0511 95.3973C67.5098 95.7747 75.8094 95.9928 81.0098 95.9247C102.286 95.6458 110.74 94.9965 110.437 95.6119C111.291 96.8219 107.51 96.542 96.3478 83.6587C91.4831 78.0438 82.0813 69.4585 72.5433 60.042C69.3977 56.9364 66.3585 53.604 63.0511 50.6875C47.0098 36.542 34.0105 22.2773 34.7091 23.4416"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M26.6856 109.627C27.1123 109.627 26.5091 107.482 25.4042 103.334C24.1242 98.5278 23.1229 95.8037 22.8638 95.4634C22.6047 95.123 22.664 95.4363 22.6369 95.6361C22.6098 95.8359 21.2335 98.2206 18.5913 103.601C15.949 108.982 14.8627 111.284 14.6899 111.057"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M17.7732 104.113C17.6938 104.22 17.6938 104.22 18.0194 104.132C18.3449 104.045 20.687 103.81 21.8303 103.673C22.9736 103.535 26.7608 103.223 26.6417 103.382C26.5225 103.542 26.4352 103.311 26.3558 103.417"
                stroke={color}
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default TriangleIcon;
