import React from 'react';

import { CLEVER } from '../../../constants';
import { getSSOAuthUrl } from '../../../helpers';
import clever from '../../../assets/images/clever.png';
import SSOButton from '../SSOButton';

const CleverButton = ({locale}) => {
    return <SSOButton href={getSSOAuthUrl(CLEVER, locale)} image={clever} name='Clever' />;
};

export default CleverButton;