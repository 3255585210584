import React from "react";
import { Helmet } from "react-helmet";

import "./styles.scss";

import ArrowFigureIcon from "../../ui/icons/ArrowFigureIcon";

const ScheduleOnboarding = () => {
    return (
        <div className="schedule-onboarding-page">
            <div className="schedule-onboarding-container">
                <div className="arrow-figure">
                    <ArrowFigureIcon />
                </div>
                <div className="schedule-onboarding-title">
                    <span className="schedule-onboarding-info">
                        To activate your Magma Math Account - schedule an
                    </span>
                    <span className="schedule-onboarding-info">
                        onboarding with one of our specialists
                    </span>
                </div>
                <div
                    className="meetings-iframe-container"
                    data-src="https://meetings-eu1.hubspot.com/meetings/martin-persson/onboarding-meeting?embed=true"
                ></div>
                <Helmet>
                    <script
                        type="text/javascript"
                        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                    ></script>
                </Helmet>
            </div>
        </div>
    );
};

export default ScheduleOnboarding;
