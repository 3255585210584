import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import SwedenFlagIcon from "../../../../ui/icons/SwedenFlagIcon";
import USFlagIcon from "../../../../ui/icons/USFlagIcon";
import { LOCALE_AUTH_ENV_CONFIG } from "../../configs";
import { APP_LOCALE, USER_REFERED_LOCALE_LOCAL_STORAGE_KEY, USER_PREFERED_LOCALE_SEARCH_PARAM, LOCALE_PATH_REGEXP } from "../../contstants";
import UKFlagIcon from "../../../../ui/icons/UKFlagIcon";
import { appLocale } from "../../helpers";

const LANGUAGE_ICON_LIST = [
    {
        locale: APP_LOCALE["english-british"],
        icon: <UKFlagIcon/>,
    },
    {
        locale: APP_LOCALE.US,
        icon: <USFlagIcon/>,
    },
    {
        locale: APP_LOCALE.swedish,
        icon: <SwedenFlagIcon />,
    },
];

const LanguageSwitch = () => {
    const [isOpenedLangList, setIsOpenedLangList] = useState(false);
    const langListRef = useRef(null);

    const currentLocaleItem = LANGUAGE_ICON_LIST.find(
        item => item.locale === appLocale
    ) || null;

    const switchLocale = (locale) => {
        const originToSwitch = LOCALE_AUTH_ENV_CONFIG[locale];
        const currentPathName = window.location.pathname;
        const currentSearchParams = window.location.search;

        const URLToSwitch = `${originToSwitch}${currentPathName.replace(LOCALE_PATH_REGEXP, '')}${currentSearchParams}`;

        const switchUrl = new URL(URLToSwitch);
        switchUrl.searchParams.append(USER_PREFERED_LOCALE_SEARCH_PARAM, true);

        localStorage.setItem(USER_REFERED_LOCALE_LOCAL_STORAGE_KEY, locale);

        window.location.replace(switchUrl);
    };

    const handleLanguageChoose = (locale) => {
        if (appLocale !== locale) {
            switchLocale(locale);
        } else {
            setIsOpenedLangList(false);
        }
    };

    const handleOpenLangList = (event) => {
        event.stopPropagation();
        setIsOpenedLangList(prevState => !prevState);
    };

    const handleClickOutside = useCallback((event) => {
        if (langListRef.current && !langListRef.current.contains(event.target)) {
            setIsOpenedLangList(false);
        }
    }, [langListRef, setIsOpenedLangList]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <>
            <div
                className="language-switch-container"
                onClick={handleOpenLangList}
            >
                {currentLocaleItem.icon}
            </div>
            {
                isOpenedLangList ? (
                        <div
                            ref={langListRef}
                            className="language-dropdown-container"
                        >
                            {
                                LANGUAGE_ICON_LIST.map(item => (
                                    <div
                                        key={item.locale}
                                        onClick={() => handleLanguageChoose(item.locale)}
                                    >
                                        {item.icon}
                                    </div>
                                ))
                            }
                        </div>
                ) : null
            }
        </>
    );
};

export default LanguageSwitch;
