const SwedenFlagIcon = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_243)">
            <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#FFDA44"/>
            <path d="M25.0436 27.8261H63.7291C61.6837 12.1252 48.2583 0 32 0C29.6107 0 27.2833 0.264125 25.0435 0.760625L25.0436 27.8261Z" fill="#0052B4"/>
            <path d="M16.6957 27.8262V3.89111C7.909 8.68536 1.61887 17.4792 0.270996 27.8264L16.6957 27.8262Z" fill="#0052B4"/>
            <path d="M16.6956 36.1738H0.270996C1.61887 46.521 7.909 55.3148 16.6957 60.109L16.6956 36.1738Z" fill="#0052B4"/>
            <path d="M25.0435 36.174V63.2393C27.2833 63.7358 29.6107 64 32 64C48.2583 64 61.6837 51.8747 63.7291 36.1738H25.0435V36.174Z" fill="#0052B4"/>
        </g>
        <defs>
            <clipPath id="clip0_2301_243">
            <rect width="64" height="64" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default SwedenFlagIcon;
