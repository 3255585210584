import React from "react";
import { COLORS } from "@magmamath/ui";

const SinusoidIcon = ({ color = COLORS.NEUTRAL_3 }) => (
    <svg
        width="200"
        height="239"
        viewBox="0 0 384 239"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M93.8374 177.778C89.5708 183.754 87.7619 183.1 89.57 179.486C91.5423 175.543 111 160.5 128.337 133C134.035 123.963 143.543 114.186 151 103C165.683 80.9756 172.618 73.2561 175.5 68.5C179.136 62.5 191.5 48.0557 215.648 48.0558C232.322 48.056 246.313 63.2549 260.983 83.5C265.426 89.6312 268.602 97.3652 274.591 103C281.5 109.5 283.472 115.869 288.195 122.31C299.632 137.906 310.226 152.256 322.069 163C337.5 177 348.976 186.352 358.182 186.322C365.265 186.298 373.5 186.322 380.5 181.321C384.77 178.271 390.428 175.643 396.5 170.801C404.532 164.397 414.055 155.4 425.847 142.785"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M139.098 219.608C139.101 226.776 140.002 217.66 140.002 203.392C140.002 183.762 142.02 150.949 140.002 94C138.05 38.9286 141.099 46.0002 140.325 18.9726C139.551 -8.05495 145.298 27.9975 144.219 28.417C143.14 28.8366 140.706 13.552 140.325 14.6931C139.85 16.1195 137.251 24.8634 136.537 26.7097M12.2603 118.384C6.63842 119.156 10.7051 116.822 23.7583 117.583C30.116 117.953 52 115.5 78.5 117.18C91.3194 117.993 105.262 116.854 119 116.813C138.151 116.756 156.95 116.92 172 117.572C194.374 118.54 208.697 117.455 220 117.572C237.061 117.748 246.605 118.73 261.5 118.297C271.971 117.992 285.333 117.735 306.404 117.804C315.109 117.833 320.177 118.136 319.835 118.499C319.588 118.761 316.495 118.236 308.813 121.649C301.132 125.062 321.737 118.451 320.786 118.164C319.597 117.804 308.533 113.589 307.106 113.114"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M214.711 113.343C215.041 112.42 214.426 111.403 214.072 112.562C213.717 113.722 213.671 116.584 213.482 118.476C213.294 120.369 215.069 121.74 215.708 122.521"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M138.147 186.019C136.752 185.641 135.119 185.24 138.195 185.546C141.27 185.853 141.506 185.876 142.713 185.757C143.919 185.639 145.149 185.283 146.143 184.905"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M133.183 48.0721C132.781 47.3153 133.278 47.1259 133.987 47.1966C134.697 47.2673 136.826 47.4793 139.428 47.7386C142.03 47.9978 145.436 47.3815 146.43 47.0026"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M68.12 111.767C68.0248 110.324 67.4958 109.236 67.8284 113.092C68.161 116.948 66.795 118.059 68.3576 121.56"
            stroke={color}
            strokeWidth="6"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
    </svg>
);

export default SinusoidIcon;
