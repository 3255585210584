import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-scanner";

import "./styles.scss";

import CloseIcon from "../../ui/icons/CloseIcon";

import CameraBorderIcon from "../../ui/icons/CameraBorderIcon";
import Logo from "../Logo";
import GlobalCircleLoader from "../Loaders/CircleLoader/GlobalCircleLoader";
import { showErrorToast } from "../../constants/toasts";

const CAMERA_WINDOW_WIDTH = 512;
const CAMERA_WINDOW_HEIGHT = 390;

const QRCode = ({ closeScanner, onScanComplete }) => {
    const { t } = useTranslation();
    const [isCameraLoading, setIsCameraLoading] = useState(true);

    const previewStyle = {
        height: CAMERA_WINDOW_HEIGHT,
        width: CAMERA_WINDOW_WIDTH,
    };

    const onScan = (data) => {
        if (data?.text) {
            onScanComplete(data.text);
            closeScanner();
        }
    };

    const onError = (error) => {
        showErrorToast({
            message: t("errorReadingQRCode"),
            toastId: t("errorReadingQRCode"),
        });
    };

    return (
        <div className="qr-code-page-container">
            <Logo />
            <div className="qr-code-container">
                <span className="qr-code-text">{t("scanQRCodeText")}</span>
                <div className="camera-border-icon">
                    <CameraBorderIcon />
                </div>
                {isCameraLoading && <GlobalCircleLoader />}
                <QrReader
                    style={previewStyle}
                    onScan={onScan}
                    onError={onError}
                    onLoad={() => setIsCameraLoading(false)}
                />
            </div>
            <div className="exit-button-container" onClick={closeScanner}>
                <CloseIcon />
            </div>
        </div>
    );
};

export default QRCode;
