import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD , USERNAME_FIELD } from '../../../constants/fieldNames';
import { TEACHER } from '../../../constants';
import FormInput from '../../AuthForm/FormInput';
import PasswordInput from '../../AuthForm/FormInput/PasswordInput';

const RegisterUserForm = (props) => {
    const { t } = useTranslation();

    return (
        <div className="input-form">
            <FormInput
                placeholder={t('firstNameText')}
                name={FIRST_NAME_FIELD}
                value={props.data.firstName.value}
                touched={props.data.firstName.touched}
                valid={props.data.firstName.touched && !Boolean(props.data.firstName.error)}
                inValid={props.data.firstName.touched && Boolean(props.data.firstName.error)}
                error={props.data.firstName.error}
                onPress={props.onChange}
            />
            <FormInput
                placeholder={t('lastNameText')}
                name={LAST_NAME_FIELD}
                value={props.data.lastName.value}
                touched={props.data.lastName.touched}
                valid={props.data.lastName.touched && !Boolean(props.data.lastName.error)}
                inValid={props.data.lastName.touched && Boolean(props.data.lastName.error)}
                error={props.data.lastName.error}
                onPress={props.onChange}
            />
            <FormInput
                placeholder={props.userType === TEACHER ? t('emailText') : t('usernameText')}
                name={USERNAME_FIELD}
                value={props.data.username.value}
                touched={props.data.username.touched}
                valid={props.data.username.touched && !Boolean(props.data.username.error)}
                inValid={props.data.username.touched && Boolean(props.data.username.error)}
                error={props.data.username.error}
                onPress={props.onChange}
                disabled={props.data.username.disable}
            />
            {
                !props.data.password.disable && (
                    <PasswordInput
                        value={props.data.password.value}
                        touched={props.data.password.touched}
                        error={props.data.password.error}
                        onChange={props.onChange}
                    />
                )
            }
        </div>
    );
};

export default RegisterUserForm;
